import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {AddPassPopupDialog} from '../my-pass-page/my-pass-page.component';

@Component({
  selector: 'app-choose-duplicate-pass-method',
  templateUrl: './choose-duplicate-pass-method.component.html',
  styleUrls: ['./choose-duplicate-pass-method.component.css']
})
export class ChooseDuplicatePassMethodComponent implements OnInit {

  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: AddPassPopupData, private router: Router, private _snackBar: MatSnackBar, private dialog: MatDialog) {
    this.isGroup = data.isGroup;
  }

  durationInSeconds = 3;
  public isGroup: boolean = false;

  ngOnInit(): void {
  }

  public duplicatePass(isGroup: boolean): void {
    this.http.get<any>('/CheckpointPass/DuplicatePass?id=' + this.data.passIdForDuplicate + '&isGroup=' + isGroup, {}).subscribe(() => {
      this._snackBar.open("Пропуск дублирован", "Скрыть");
    });
  }

  public duplicatePassCanChangeAfterAdd(isGroup: boolean) {
    var addPassPopupData: AddPassPopupData = {
      isGroup: isGroup,
      passIdForDuplicate: this.data.passIdForDuplicate
    }
    const dialogRef = this.dialog.open(AddPassPopupDialog, {
      data: addPassPopupData
    });
  }
}

interface AddPassPopupData {
  passIdForDuplicate: number | null;
  isGroup: boolean;
}
