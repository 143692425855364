<div class="entrance">
  <div class="entrance__wrapper" style="text-align: center;">
    <div class="entrance__logo-block">
      <a class="entrance__logo-img">
        <img class="images entrance__logo-img" src="img/logo-big.png" alt="logo">
      </a>
    </div>
    <div *ngIf="confirmed == '0'" class="entrance__text">Ожидайте...</div>
    <div *ngIf="confirmed == '200'" class="entrance__text">Email подтверждён</div>
    <div *ngIf="confirmed == '400'" class="entrance__text">Неверный код</div>
    <mat-spinner *ngIf="confirmed == '0'"></mat-spinner>
    <mat-icon
      *ngIf="confirmed == '200'"
      style="
        font-size: 118px;
        margin: 0 auto;
        width: 118px;">check_circle_outline
    </mat-icon>
    <mat-icon
      *ngIf="confirmed == '400'"
      style="
        font-size: 118px;
        margin: 0 auto;
        width: 118px;">error_outline
    </mat-icon>
  </div>
</div>
