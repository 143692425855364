<div class="entrance">
  <div class="entrance__wrapper">
    <div class="entrance__logo-block">
      <a class="entrance__logo-img">
        <img class="images entrance__logo-img" src="img/logo-big.png" alt="logo">
      </a>
    </div>
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Вход">
        <div class="entrance__block-form">
          <form class="entrance__form" action="">
            <input name="phoneLog" [(ngModel)]="userReg.phone" type="tel" prefix="+7 " mask="(000) 000-00-00"
                   [validation]="true" class="entrance__input  js-entrance__input shadow" required="tel"
                   [showMaskTyped]="true" (input)="checkValidInputLogin()">
            <input name="passwordLog" [(ngModel)]="userReg.password" type="password"
                   class="entrance__input entrance__input-remind" required="" placeholder="Пароль"
                   (input)="checkValidInputLogin()">
            <div class="entrance__detail-block">
              <div class="entrance__wrong-text entrance__wrong-hidden">Неверный логин или пароль</div>
            </div>
            <button class="entrance__button js-entrance__button" (click)="Login()" [disabled]="activateLoginButton"
                    [ngStyle]="{'background': activateLoginButton ? '#696969' : '#FF7027' }">Войти
            </button>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="Регистрация">
        <div class="entrance__block-form">
          <form class="entrance__form" action="">
            <input name="firstNameReg" [(ngModel)]="userReg.firstName" type="text"
                   class="entrance__input  js-entrance__input shadow" required="" placeholder="  Имя"
                   (input)="checkValidInput()">
            <input name="lastNameReg" [(ngModel)]="userReg.lastName" type="text"
                   class="entrance__input  js-entrance__input shadow" required="" placeholder="  Фамилия"
                   (input)="checkValidInput()">
            <input name="patronymicReg" [(ngModel)]="userReg.patronymic" type="text"
                   class="entrance__input  js-entrance__input shadow" required="" placeholder="  Отчество"
                   (input)="checkValidInput()">
            <mat-select name="organizationIdReg" [(ngModel)]="userReg.organizationId" placeholder="Организация"
                        style="margin-bottom: 25px;">
              <mat-option *ngFor="let organization of organizationdNames" value="{{organization.id}}"
                          (click)="checkValidInput()">{{organization.name}}</mat-option>
            </mat-select>
            <mat-select name="roleReg" [(ngModel)]="userReg.claimedRole" placeholder="Должность"
                        style="margin-bottom: 25px;">
              <mat-option *ngFor="let role of roles" value="{{role}}" (click)="checkValidInput()">{{role}}</mat-option>
            </mat-select>
            <input name="emailReg" [(ngModel)]="userReg.email" type="email"
                   class="entrance__input  js-entrance__input shadow" required="" placeholder="  Почта"
                   (input)="checkValidInput()">
            <input name="phoneReg" [(ngModel)]="userReg.phone" type="tel" prefix="+7 " mask="(000) 000-00-00"
                   [showMaskTyped]="true" class="entrance__input  js-entrance__input shadow" required=""
                   (input)="checkValidInput()">
            <input name="passwordReg" [(ngModel)]="userReg.password" type="password"
                   class="entrance__input entrance__input-remind" required="" placeholder="  Пароль"
                   (input)="checkValidInput()">
            <button class="entrance__button js-entrance__button" [disabled]="activateRegButton"
                    [ngStyle]="{'background': activateRegButton ? '#696969' : '#FF7027' }" (click)="Register()">
              Далее
            </button>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="Напомнить">
        <div class="entrance__block-form">
          <form class="entrance__form" action="">
            <input name="phoneRem" [(ngModel)]="userReg.phone" type="tel" prefix="+7 " mask="(000) 000-00-00"
                   class="entrance__input  js-entrance__input shadow" required="" [showMaskTyped]="true"
                   (input)="checkValidInputRemind()">
            <button class="entrance__button js-entrance__button" [disabled]="activateRemindButton"
                    [ngStyle]="{'background': activateRemindButton ? '#696969' : '#FF7027' }" (click)="SendLoginSMS()">
              Далее
            </button>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
