import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserPublicData} from "../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class ShopUsersService {

  constructor(protected http: HttpClient) {
  }

  public getShopUsrts(): any {
    this.http.get<UserPublicData[]>('/Account/DataAboutAllShopAccounts').subscribe(shopUsers => {
      console.log('getShopUsrts: ', shopUsers)
      return shopUsers;
    }, error => {
      return []
    })
  }
}
