import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {result} from 'lodash';
import {AppSettings} from '../models/appSettings.models';
import {ScheduleForSend} from '../models/scheduleForSend.model';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  public schedule!: ScheduleForSend;

  constructor(private http: HttpClient, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.http.get<ScheduleForSend>('/Schedule/GetSchedules').subscribe(schedule => {
      this.schedule = schedule;
    });
  }

  public saveSettings() {
    this.http.post('/Schedule/SaveChanges', this.schedule, {}).subscribe(result => {
      this.dialog.closeAll();
    });
  }

  public getDefault(isDefault: boolean) {
    if (!isDefault) {
      this.http.get<ScheduleForSend>('/Schedule/GetDefaultSchedules').subscribe(schedule => {
        this.schedule.firstShift = schedule.firstShift;
        this.schedule.secondShift = schedule.secondShift;
        this.schedule.thirdShift = schedule.thirdShift;
        this.schedule.isShiftMode = schedule.isShiftMode;
        this.schedule.timerHours = schedule.timerHours;
      });
    } else {
      this.http.get<ScheduleForSend>('/Schedule/GetSchedules').subscribe(schedule => {
        this.schedule.firstShift = schedule.firstShift;
        this.schedule.secondShift = schedule.secondShift;
        this.schedule.thirdShift = schedule.thirdShift;
        this.schedule.isShiftMode = schedule.isShiftMode;
        this.schedule.timerHours = schedule.timerHours;
      });
    }
  }
}
