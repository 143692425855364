<!-- <div [className]="classes.actions">
  <button ly-button color="primary" (click)="_fileInput.click()">
    <ly-icon>image</ly-icon>
    <span>Select File</span>
  </button>
  <span [className]="classes.flex"></span>
  <input #_fileInput type="file" (change)="cropper.selectInputEvent($event)" accept="image/*" hidden>
</div> -->

<div *ngIf="cropper.isLoaded">
  <button (click)="cropper.zoomIn()" ly-button appearance="icon">
    <ly-icon>zoom_in</ly-icon>
  </button>
  <button (click)="cropper.zoomOut()" ly-button appearance="icon">
    <ly-icon>zoom_out</ly-icon>
  </button>
  <button (click)="cropper.center()" ly-button appearance="icon">
    <ly-icon>filter_center_focus</ly-icon>
  </button>
  <button (click)="cropper.rotate(-90)" ly-button appearance="icon">
    <ly-icon>rotate_90_degrees_ccw</ly-icon>
  </button>
  <button (click)="cropper.fit()" ly-button>
    <mat-icon>fit_screen</mat-icon>
  </button>
  <button (click)="cropper.fitToScreen()" ly-button>
    <mat-icon>crop_free</mat-icon>
  </button>
  <button (click)="cropper.setScale(1)" ly-button>
    <mat-icon>crop_original</mat-icon>
  </button>
  <button (click)="cropper.clean()" ly-button>
    <mat-icon>delete</mat-icon>
  </button>
</div>

<ly-img-cropper [withClass]="classes.cropper"
                class="drag-drop"
                [config]="myConfig"
                (cropped)="onCropped($event)"
                (ready)="ready = true"
                (cleaned)="ready = false">
  <span style="text-align: center;">Нажмите или перетащите изображение</span>
</ly-img-cropper>
<button class="cropp-button" *ngIf="cropper.isLoaded" (click)="cropper.crop()" mat-dialog-close>
  <mat-icon>crop</mat-icon>
</button>
