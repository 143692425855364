<div class="add-organization-popup js-add-organization-popup">
  <div class="add-organization-popup__wrapper">
    <div class="add-organization-popup__title">Настройки организации</div>
    <div class="add-organization-popup__hr"></div>
    <div class="add-organization-popup__block">
      <div class="card-edit-popup__edit-photo">
        <img class="images card-edit-popup__chose-photo" style="border-radius: 11px; margin-top: 11px;"
             src="{{data.imgUrl}}" alt="chose">
        <div (click)="cropImageOrganizationPopupDialog()" class="card-edit-popup__chose-photo"
             *ngIf="data.id == myUserData.organizationId"
             style="
                    background-color: rgb(43 43 43 / 43%) !important;
                    border-radius: 19% !important;
                    display: hidden !important;
                    width: 56px !important;
                    height: 56px !important;
                    padding: 28%;
                    margin-top: 11px;"
             alt="chose">
          <mat-icon style="color:#fff">file_upload</mat-icon>
        </div>
      </div>
      <div class="add-organization-popup__block-inputs">
        <div class="add-organization-popup__selector">
          <div class="card-edit-popup__label">
            Форма:
          </div>
          <mat-select [(ngModel)]="data.form">
            <mat-option value="ООО ">ООО</mat-option>
            <mat-option value="ИП ">ИП</mat-option>
            <mat-option value="АО ">АО</mat-option>
            <mat-option value="ЗАО  ">ЗАО</mat-option>
          </mat-select>
        </div>
        <label>
          <span class="card-edit-popup__label">Наименование:</span>
          <input type="text" #name class="card-edit-popup__input" [(ngModel)]="data.name">
        </label>
      </div>
    </div>
    <div class="add-organization-popup__address">
      <div class="card-user-popup__icon-link">
        <img class="images card-user-popup__icon-link-img" src="img/hove-svg.svg" alt="home">
      </div>
      <label>
        <span class="card-edit-popup__label">Адрес:</span>
        <input type="text" #address class="card-edit-popup__input" [(ngModel)]="data.address">
      </label>
    </div>
    <div class="card-edit-popup__bottoms" style="width: 100%;">
      <a class="card-edit-popup__delete-btn js-card-edit-popup__delete-btn" (click)="deleteOrganization()"
         mat-dialog-close
         style="margin-right: auto;">
        <img class="card-edit-popup__basket-img" src="img/card-edit-popup__-basket.svg" alt="img">
        <span class="card-edit-popup__basket-span">Деактивировать организацию</span>
      </a>
      <div class="card-edit-popup__actions">
        <button class="card-edit-popup__removal js-add-organization-removal" mat-dialog-close>Отмена</button>
        <button class="card-edit-popup__conservation js-organization-popup__continue" (click)="editOrganization()"
                mat-dialog-close>Сохранить
        </button>
      </div>
    </div>
  </div>
</div>
