<div class="legend-box">
  <div class="legend-item">
    <div class="legend-color-box">
      <div class="legend-color" style="background: white"></div>
      <div class="legend-color" style="background: #ffa500"></div>
    </div>
    <span>сегодня ожидаем</span>
  </div>

  <div class="legend-item">
    <div class="legend-color-box">
      <div class="legend-color" style="background: darkviolet"></div>
      <div class="legend-color" style="background: #ffa500"></div>
    </div>
    <span>ожидаем</span>
  </div>

  <div class="legend-item">
    <div class="legend-color-box">
      <div class="legend-color" style="background: #de37e2"></div>
      <div class="legend-color" style="background: #ffa500"></div>
    </div>
    <span>не ожидаем сегодня</span>
  </div>

  <div class="legend-item">
    <div class="legend-color-box">
      <div class="legend-color" style="background: #3e94ff"></div>
      <div class="legend-color" style="background: #3e94ff"></div>
    </div>
    <span>вне територии</span>
  </div>

  <div class="legend-item">
    <div class="legend-color-box">
      <div class="legend-color" style="background: green"></div>
      <div class="legend-color" style="background: green"></div>
    </div>
    <span>на територии</span>
  </div>

  <div class="legend-item">
    <div class="legend-color-box">
      <div class="legend-color" style="background: white"></div>
      <div class="legend-color" style="background: white"></div>
    </div>
    <span>автомобиль на територии</span>
  </div>

  <div class="legend-item">
    <div class="legend-color-box">
      <div class="legend-color" style="background: red"></div>
      <div class="legend-color" style="background: red"></div>
    </div>
    <span>прострочен</span>
  </div>
</div>
