export enum Roles {
  Director = "Director",
  Administrator = "Administrator",
  SeniorManager = "SeniorManager",
  Manager = "Manager",
  SeniorSecurityOfficer = "SeniorSecurityOfficer",
  SecurityOfficer = "SecurityOfficer",
  Candidate = "Candidate",
  Guest = "Guest"
}

export enum RolesForRegistration {
  // Administrator = "Administrator",
  // SeniorManager = "SeniorManager",
  // Manager = "Manager",
  // SeniorSecurityOfficer = "SeniorSecurityOfficer",
  // SecurityOfficer = "SecurityOfficer",
  Director = "Director",
  Administrator = "Administrator",
  SeniorManager = "SeniorManager",
  Manager = "Manager",
  SeniorSecurityOfficer = "SeniorSecurityOfficer",
  SecurityOfficer = "SecurityOfficer",
  // Candidate = "Candidate",
  // Guest = "Guest"
}
