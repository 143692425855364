<!-- <mat-progress-bar mode="query" style="position: fixed;" *ngIf="paymentData.length == 0"></mat-progress-bar> -->
<!--*ngIf="tabs.length == 0"-->
<div class="users-list" style="background-color: rgba(245, 245, 220, 0);">
  <div class="users-list__wrapper">
    <div class="container">
      <div class="users-list__top">
        <div class="users-list__top-main">
          <div class="users-list__title">Эквайринг</div>
          <div class="users-list__line"></div>
          <!-- <button mat-raised-button (click)="openSwagger()" class="swagger-button">
              Документация (Swagger)
          </button> -->
        </div>
        <div class="security-page__create js-my-page__createe" (click)="addAquaringDialog()">
          <div class="security-page__create-img-block">
            <img class=" images " src="img/plus-small-add-white.svg" alt="">
          </div>
          <div class="security-page__create-text">
            Добавить
          </div>
        </div>
      </div>

      <!-- <div class="users-list__blocks-companies">
          <mat-card *ngFor="let paymentQr of getPaymentData()" class="item">
              <img src="data:image/png;base64,{{paymentQr.qrCodeBase64}}" class="item-img" alt=""><br>
              <div class="inside-block">
                  <h5 class="">{{paymentQr.sum}}</h5>
                  <h5>{{paymentQr.purpose}}</h5>
              </div>
          </mat-card>
      </div> -->
      <div class="parent">

        <mat-card class="example-card" *ngFor="let qrAquaring of qrAquaringsData"
                  [ngStyle]="{'border': qrAquaring.floatingCost ? '5px solid #ea5c0a57' : 'none' }">
          <mat-card-header>
            <!-- <div mat-card-avatar class="example-header-image"></div> -->
            <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
          </mat-card-header>
          <img mat-card-image src="data:image/png;base64,{{qrAquaring.paymentQrBase64}}"
               [cdkCopyToClipboard]="qrAquaring.payentLink"
               alt="Photo of a Shiba Inu">
          <div>
            <mat-card-title style="display: inline-block;">{{toTrueFormat(qrAquaring.amount)}} ₽</mat-card-title>
            <mat-card-title style="float: right; display: inline-block;"></mat-card-title>
          </div>
          <mat-card-content>
            <p *ngIf="qrAquaring.name.length > 0" style="font-size: 18px; font-weight: bold;">
              {{qrAquaring.name}}
            </p>
            <br>
            <p *ngIf="qrAquaring.description.length > 0 && qrAquaring.name != qrAquaring.description">
              {{qrAquaring.description}}
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>picture_as_pdf</mat-icon>
      <span>PDF</span>
    </button>
    <button mat-menu-item>
      <mat-icon>forward_to_inbox</mat-icon>
      <span>Отправить на электронную почту</span>
    </button>
    <button mat-menu-item>
      <mat-icon>settings</mat-icon>
      <span>Изменить</span>
    </button>
    <button mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span>Удалить</span>
    </button>
  </mat-menu>
