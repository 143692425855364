import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Order} from 'src/app/models/order.models';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  constructor(private route: ActivatedRoute, public http: HttpClient) {
  }

  public order!: Order;

  ngOnInit(): void {
    var orderNumber = this.route.snapshot.params['id'];
    this.http.get<Order>('/api/Payment/GetOrderByOrderNumber?orderNumber=' + orderNumber).subscribe(result => {
      this.order = result;
      console.log(result);
    });
  }

}
