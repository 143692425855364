<div class="add-pass-popup js-add-pass-popup">
  <div class="add-pass-popup__wrapper">
    <div class="add-pass-popup__top">
      <div class="add-pass-popup__top-block">
        <div class="add-pass-popup__title">Заявка № {{data.id}}</div>
        <!-- <div class="add-pass-popup__when">от 22 Ареля, 2021</div> -->
      </div>
      <div class="add-pass-popup__exit js-add-pass-popup__exit" mat-dialog-close>
        <svg>
          <use xlink:href="img/icons/icons.svg#exit-cross"></use>
        </svg>
      </div>
    </div>
    <div class="add-pass-popup__main">
      <div class="add-pass-popup__info-guest">
        <div class="add-pass-popup__info-guest-title">
          Информация о госте
        </div>

        <div class="add-pass-popup__info-guest-block js-add-pass-popup__info-guest-block">
          <!-- <div class="add-pass-popup__info-guest-amount">
              Гость <span class="add-pass-popup__info-guest-number"></span>
          </div> -->
          <label class="add-pass-popup__label-surname">
            <span class="add-pass-popup__span-title">Имя:</span>
            <input class="add-pass-popup__input-full-name b2px46" [(ngModel)]="data.firstName" placeholder="Имя:">
            <span class="add-pass-popup__span-title">Фамилия:</span>
            <input class="add-pass-popup__input-full-name b2px46" [(ngModel)]="data.lastName" placeholder="Фамилия:">
            <span class="add-pass-popup__span-title">Отчество:</span>
            <input class="add-pass-popup__input-full-name b2px46" [(ngModel)]="data.patronymic" placeholder="Отчество:">
            <span class="add-pass-popup__span-title">Документ:</span>
            <mat-select [(ngModel)]="data.documentType" class="b2px46">
              <mat-option value="Паспорт">Паспорт</mat-option>
              <mat-option value="Биометрический">Биометрический</mat-option>
              <mat-option value="Водительское удостоверение">Водительское удостоверение</mat-option>
            </mat-select>
            <span class="add-pass-popup__span-title">Документ:</span>
            <label class="add-pass-popup__label-number-doc" *ngIf="data.documentType == 'Водительское удостоверение'">
              <input type="text" class="add-pass-popup__number-doc b2px46" mask="00-00-000000" [showMaskTyped]="true"
                     [(ngModel)]="data.document">
            </label>
            <label class="add-pass-popup__label-number-doc" *ngIf="data.documentType == 'Биометрический'">
              <input type="text" class="add-pass-popup__number-doc b2px46" mask="AAAAAAAAA" [showMaskTyped]="true"
                     [(ngModel)]="data.document">
            </label>
            <label class="add-pass-popup__label-number-doc" *ngIf="data.documentType == 'Паспорт'">
              <input type="text" class="add-pass-popup__number-doc b2px46" [specialCharacters]="[ '№' ]"
                     mask="AA№000000000" [showMaskTyped]="true" [(ngModel)]="data.document">
            </label>
          </label>
          <!-- <div class="add-pass-popup__documents">

          </div> -->
        </div>
      </div>
      <div class="add-pass-popup__entry">
        <div class="add-pass-popup__entry-title">Информация о въезде</div>
        <span class="add-pass-popup__span-title">Способ въезда:</span>
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="data.type">
          <mat-button-toggle value="Пешком" (click)="checkValidInput()">
            <mat-icon>directions_walk</mat-icon>
            <div class="status">Пешком</div>
          </mat-button-toggle>
          <mat-button-toggle value="Легковой автомобиль" (click)="checkValidInput()">
            <mat-icon>drive_eta</mat-icon>
            <div class="status">Легковой автомобиль</div>
          </mat-button-toggle>
          <mat-button-toggle value="Грузовой автомобиль" (click)="checkValidInput()">
            <mat-icon>local_shipping</mat-icon>
            <div class="status">Грузовой автомобиль</div>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <div *ngIf="data.type == 'Легковой автомобиль' || data.type == 'Грузовой автомобиль'"
             class="tabcontent js-tabcontent-entry" style="display: block">
          <div class="add-pass-popup__tabcontent">
            <label class="add-pass-popup__label-tab">
              <span class="add-pass-popup__span-title" *ngIf="gosShortNumber.length == 0">Гос номер (полный)</span>
              <input *ngIf="gosShortNumber.length == 0" type="text" mask="V-LLL-VV-LL||V-LLL-VV-LLL"
                     [patterns]="customPatterns" [showMaskTyped]="true" class="add-pass-popup__tab-input b2px46"
                     [(ngModel)]="data.governmentNumber" (input)="checkValidInput()">
              <h3 *ngIf="data.governmentNumber.length == 0 && gosShortNumber.length == 0"
                  style="margin-left: auto; margin-right: auto; margin-top: 4%; margin-bottom: 4%; text-align: center;">
                или</h3>
              <span class="add-pass-popup__span-title" style="margin-top: 2%;"
                    *ngIf="data.governmentNumber.length == 0">Гос номер (короткий)</span>
              <input *ngIf="data.governmentNumber.length == 0" style="margin-top: 2%;" type="text" mask="LLL"
                     [patterns]="customPatterns" [showMaskTyped]="true" class="add-pass-popup__tab-input b2px46"
                     [(ngModel)]="gosShortNumber" (input)="checkValidInput()">
            </label>
            <!-- mask="9-999-99||9-999-99-99||999" -->
            <div class="add-pass-popup__tab-select">
              <span class="add-pass-popup__span-title">Марка:</span>
              <input type="text"
                     class="add-pass-popup__tab-input ng-pristine ng-valid ng-touched b2px46"
                     placeholder="Марка"
                     aria-label="Number"
                     matInput
                     [formControl]="myControl"
                     [matAutocomplete]="auto"
                     [(ngModel)]="data.carBrand">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let car of filteredOptions | async" value="{{car}}" [value]="car">
                  {{car}}
                </mat-option>
              </mat-autocomplete>
              <!-- <mat-select [(ngModel)]="data.carBrand">
                  <mat-option *ngFor="let car of cars" value="{{car}}">{{car}}</mat-option>
              </mat-select>-->
            </div>
            <label class="add-pass-popup__checkbox">
              <mat-checkbox type="checkbox" class="add-pass-popup__input-checked"
                            [(ngModel)]="data.cargo"></mat-checkbox>
              <!-- <span class="add-pass-popup__span-fake"></span> -->
              <span class="add-pass-popup__checkbox-text" style="margin-left: auto;"> Машина с грузом</span>
            </label>
          </div>
          <input disabled *ngIf="warningGosNumber" type="text" style="margin-top: 2%;background: #ea5c0a;color: white;"
                 class="add-pass-popup__tab-input b2px46" value="Гос номер не будет распознан автоматически">
        </div>
      </div>
      <div class="add-pass-popup__purpose" [formGroup]="range">
        <mat-date-range-input [rangePicker]="picker" class="add-pass-popup__number-doc">
          <input matStartDate formControlName="start" placeholder="Start date" [disabled]="true">
          <input matEndDate formControlName="end" placeholder="End date" [disabled]="true">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker disabled="false" #picker></mat-date-range-picker>
      </div>
      <div class="add-pass-popup__purpose">
        <div class="add-pass-popup__purpose-title">Цель визита</div>
        <!-- <div class="add-pass-popup__purpose-add-text" style="display: inline-flex; align-items: center; position: absolute; right: 10%;">Выбрать цель из списка
               <div class="add-pass-popup__purpose-add-images" style="display: inline-flex; align-content: flex-end;">
                   <img class="add-pass-popup__purpose-add-img" src="img/add-pass-popup__purpose-add-cross.svg" alt="cross">
               </div>
            </div> -->
        <div class="add-pass-popup__purpose-block">
          <textarea class="add-pass-popup__purpose-textarea" id="" cols="30" rows="10"
                    placeholder="Введите цель визита вашего гостя" [(ngModel)]="data.thePurposeOfTheVisit"></textarea>
          <div class="add-pass-popup__purpose-add" [matMenuTriggerFor]="menu">
            <!-- [matMenuTriggerFor]="menu" -->
            <div class="add-pass-popup__purpose-add-images">
              <svg>
                <use xlink:href="img/icons/icons.svg#add-pass-popup__purpose-add-cross"></use>
              </svg>
            </div>
          </div>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="data.thePurposeOfTheVisit = purposeOfTheVisit"
                    *ngFor="let purposeOfTheVisit of purposesOfTheVisit">
              <mat-icon>west</mat-icon>
              <span>{{purposeOfTheVisit}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="add-pass-popup__bottom">
      <div class="add-pass-popup__bottom-buttons">
        <button class="add-pass-popup__btn-cancel" mat-dialog-close>Отмена</button>
        <button class="add-pass-popup__btn-send" (click)="changePass()" mat-dialog-close>Сохранить</button>
        <!-- (click)="createPass()" -->
      </div>
    </div>
  </div>
</div>
