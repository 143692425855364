<!-- <p style="text-align: center;"></p> -->
<div class="lable-div">
  <p class="lable-text">Инструкция</p>
</div>
<img src="img/iOS-pwa-gif.gif" class="gif-iPhone" alt="">
<div class="share-div">
  <p class="share-text">1. Поделиться</p>
  <mat-icon class="share-icon">ios_share</mat-icon>
</div>
<!-- <p>и</p> -->
<div class="add-div">
  <p class="add-text">2. На экран "Домой"</p>
  <mat-icon class="add-icon">add_box</mat-icon>
</div>
<div class="add-add-div">
  <p class="add-add-text">3. Добавить</p>
</div>
