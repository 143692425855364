import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {HubConnectionState} from '@microsoft/signalr';
import {CreateIntegrationKeyComponent} from '../create-integration-key/create-integration-key.component';
import {LocalStorageService} from '../local-storage.service';
import {IntegrationKeys} from '../models/integrationKeys.model';
import {SignalRService} from '../signal-r.service';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.css']
})
export class ApiComponent implements OnInit {
  integrationKeys: IntegrationKeys[] = [];

  constructor(public http: HttpClient, public dialog: MatDialog, private signalr: SignalRService, public localStorag: LocalStorageService) {
  }

  ngOnInit(): void {
    this.signalr.onconnected.subscribe(() => {
      if (this.localStorag.get("SignalR") == HubConnectionState.Connected) {
        this.subscribeOnSignalR();
      }
    });
    this.http.get<IntegrationKeys[]>('/IntegrationKeys/GetMyKeys', {}).subscribe(response => {
      this.integrationKeys = response;
    });
  }

  private subscribeOnSignalR() {
    this.signalr.connection.on("IntegrationKeyGet", (action: string, integrationKey: IntegrationKeys) => {
      if (action == "add") {
        this.integrationKeys.push(integrationKey);
      } else if (action == "delete") {
        for (let i = 0; i < this.integrationKeys.length; i++) {
          if (this.integrationKeys[i].id == integrationKey.id) {
            this.integrationKeys.splice(i, 1);
          }
        }
      }
    });
  }

  deleteIntegrationKey(id: number) {
    this.http.delete('/IntegrationKeys/DeleteKey?id=' + id, {}).subscribe();
  }

  createIntegrationKeyDialog(): void {
    const dialogRef = this.dialog.open(CreateIntegrationKeyComponent);
  }

  openSwagger() {
    window.open("/swagger/index.html", "_blank");
  }
}
