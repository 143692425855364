import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {Mail} from '../models/mail.models';
import {Roles} from '../models/roles.moel';
import {UserPublicData} from '../models/user.model';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.css']
})
export class SendMailComponent implements OnInit {
  public mail: Mail = {
    subject: "",
    body: "",
    rrecipientUserId: 0
  };
  public user: UserPublicData = {
    id: -1,
    firstName: "Имя",
    lastName: "Фамилия",
    patronymic: "Отчество",
    phone: "",
    email: "",
    phoneIsVerifed: false,
    emailIsVerifed: false,
    avatar: "/img/account_circle_black_24dp.svg",
    organizationId: 0,
    claimedRole: Roles.Candidate,
    role: Roles.Candidate,
  };

  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: number, private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.mail.rrecipientUserId = this.data;
    this.http.get<UserPublicData>('/Account/DataAboutAccount?id=' + this.mail.rrecipientUserId).subscribe(user => {
      this.user = user;
    });
  }

  async sendMail() {
    this.mail.subject = `Пост охраны`;
    await this.http.post('/Mail/Send', this.mail).subscribe(result => {
      this._snackBar.open("Письмо отправлено");
    });
  }
}
