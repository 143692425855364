import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Product} from 'src/app/models/product.models';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(protected http: HttpClient) {
  }

  public products!: Product[];
  public product: Product = {
    "id": 0,
    "categoryId": 0,
    "price": 5000,
    "name": "Test",
    "description": "Test product",
    "sKU": "87998798797",
    "bankTransferQr": "",
    "sberAcquiringQr": "",
    "sberAcquiringFormUrl": "",
    "isActive": true
  }

  protected getProducts(): void {
    this.http.get<Product[]>('/api/product/GetProducts').subscribe(response => {
      this.products = response;
      console.log(this.products);
    });
  }

  public addProduct(): void {
    this.http.put('/api/product/AddProduct', this.product).subscribe(() => {
      console.log("addProduct");
    });
  }
}
