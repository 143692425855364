<h1 mat-dialog-title>Добавить Qr код для оплаты</h1>
<div mat-dialog-content>
  <p>
    <span class="add-pass-popup__span-title">Сумма ₽</span>
    <input matInput type='text' mask="separator.2" thousandSeparator=" " [(ngModel)]="paymentData.sum"/>
    <span class="add-pass-popup__span-title">Назначение</span>
    <textarea matInput [(ngModel)]="paymentData.purpose"></textarea>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Отмена</button>
  <button mat-button mat-dialog-close class="w-100" (click)="generateQrCode()">Добавить</button>
</div>
