import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PaymentDataFromClient, QrPayment} from 'src/app/models/paymentData.model';
import {SignalRService} from 'src/app/signal-r.service';
import {AddQrCodeComponent} from '../add-qr-code/add-qr-code.component';

@Injectable({
  providedIn: 'root'
})
export class PaymentQrServiceService {

  constructor(protected http: HttpClient, protected dialog: MatDialog, protected signalr: SignalRService, protected _snackBar: MatSnackBar) {
    this.http.get<QrPayment[]>('api/Payment/GetQrCodes').subscribe(result => this.paymentData = result);
    this.signalr.connection.on("QrPaymentForOrganizationGet", (qrPayment: QrPayment) => {
      console.log();
      let paymentDat = this.paymentData.find(x => x.id == qrPayment.id);
      if (paymentDat == null) {
        this.paymentData.unshift(qrPayment);
      } else {
        paymentDat = paymentDat;
      }
    });
  }

  public paymentData: QrPayment[] = [];

  ngOnInit(): void {

  }

  public openAddQrCodeDialog() {
    const dialogRef = this.dialog.open(AddQrCodeComponent);
  }

  public getPaymentData(): QrPayment[] {
    return this.paymentData;
  }

  public increaseQrCode() {
    this._snackBar.open("Функция 'Увеличить' в разработке", "Скрыть");
  }

  public pdfQrCode() {
    this._snackBar.open("Функция 'PDF' в разработке", "Скрыть");
  }

  public sendByEmailQrCode() {
    this._snackBar.open("Функция 'Отправить на электронную почту' в разработке", "Скрыть");
  }

  public changeQrCode() {
    this._snackBar.open("Функция 'Изменить' в разработке", "Скрыть");
  }

  public deleteQrCode() {
    this._snackBar.open("Функция 'Удалить' в разработке", "Скрыть");
  }

  public functionInDevelopment() {
    this._snackBar.open("Функция в разработке", "Скрыть");
  }
}
