<!-- <mat-progress-bar mode="query" style="position: fixed;" *ngIf="orders.length == 0"></mat-progress-bar> -->
<div class="security-page">
  <div class="security-page__wrapper">
    <div class="security-page__container">
      <div class="users-list__top">
        <div class="users-list__top-main" style="background-color: #f8f8fa;">
          <div class="users-list__title">Пользователи магазина</div>
          <div class="users-list__line"></div>
          <div class="security-page__top-spec-block">
            <div class="users-list__search-block">
              <div class="users-list__search-img-block">
                <img class=" images users-list__search-img" src="img/lupa.svg" alt="lupa">
              </div>
              <form class="example-form">
                <input
                  class="users-list__search-input"
                  type="text"
                  placeholder="Поиск"
                  [formControl]="control"
                  [matAutocomplete]="auto"
                  [(ngModel)]="searchText">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option style="display: none;" *ngFor="let x of filteredShopUsers | async"
                              [value]="x.lastName+ ' ' + x.firstName + ' ' + x.patronymic">

                    {{x.lastName}} {{x.firstName}} {{x.patronymic}}
                  </mat-option>
                </mat-autocomplete>
              </form>
            </div>
          </div>
        </div>
        <div class="security-page__create js-my-page__createe" (click)="addGuest()">
          <div class="security-page__create-img-block">
            <img class=" images " src="img/plus-small-add-white.svg" alt="">
          </div>
          <div class="security-page__create-text">
            Добавить пользователя
          </div>
        </div>
      </div>

      <div class="example-section">
        <!--            <h4>Select your toppings:</h4>-->
        <div>
          <mat-checkbox class="filter-item" [(ngModel)]="phoneIsVerifiedFilter" (input)="_filter(searchText)">
            Подтверждён номер телефона
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox class="filter-item" [(ngModel)]="phoneIsNotVerifiedFilter" (input)="_filter(searchText)">Не
            подтверждён номер телефона
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox class="filter-item" [(ngModel)]="emailIsVerifiedFilter" (input)="_filter(searchText)">
            Подтверждена электронная почта
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox class="filter-item" [(ngModel)]="emailIsNotVerifiedFilter" (input)="_filter(searchText)">Не
            подтверждена электронная почта
          </mat-checkbox>
        </div>
      </div>

      <!--          <section class="example-section" [formGroup]="paymentFilter">-->
      <!--            <h4>You chose:</h4>-->
      <!--            {{paymentFilter.value | json}}-->
      <!--          </section>-->
      <div class="security-page__main">
        <div id="all" class="tabcontent tabcontent-all js-tabcontent" style="display: block">
          <div class="security-page__all table-orders">
            <div class="security-page__all-top my-page__all-top table-orders__header">
              <div class="security-page__title-category d-flex flex-column flex-nowrap">
                <mat-checkbox class="example-margin" [(ngModel)]="checker" (input)="checkAll()"></mat-checkbox>
                <mat-select class="action-select" placeholder="Действие">
                  <mat-option (click)="verifyPhones()">Верифицировать номер телефона</mat-option>
                  <mat-option (click)="setTemporaryPassword()">Установить временный пароль</mat-option>
                </mat-select>
              </div>
              <div class="security-page__title-category  ">
                ФИО
              </div>
              <div class="security-page__title-category  ">
                Номер телефона
              </div>
              <div class="security-page__title-category  ">
                Электронная почта
              </div>
              <div class="security-page__title-category  ">
                Сумма покупок
              </div>
            </div>
            <div class="security-page__item  pedestrian-group table-orders__row"
                 *ngFor="let user of filteredForSowShopUsers">
              <div class="security-page__info-item table-orders__col">
                <div class="security-page__id">

                </div>
              </div>
              <div class="security-page__info-item table-orders__col">
                <div class="security-page__id">
                  <p>
                    <mat-checkbox class="example-margin" [(ngModel)]="user.checked">
                      {{user.lastName}} {{user.firstName}} {{user.patronymic}}
                    </mat-checkbox>
                  </p>
                </div>
              </div>
              <div class="security-page__guest table-orders__col">
                <div class="security-page__info-name-person">
                  <a href="tel:+7{{user.phone}}"
                     [ngStyle]="{'color':user.phoneIsVerifed ? 'green' : 'red' }">+7{{user.phone}}</a>
                </div>
              </div>
              <div class="security-page__guest table-orders__col">
                <div class="security-page__info-name-person">
                  <p>
                    <a href="mailto:{{user.email}}"
                       [ngStyle]="{'color':user.emailIsVerifed ? 'green' : 'red' }">{{user.email}}</a>
                  </p>
                </div>
              </div>
              <div class="security-page__guest table-orders__col">
                <div class="security-page__info-name-person">
                  <p *ngIf="user.sum != null">{{user.sum}} руб</p>
                  <p *ngIf="user.sum == null">Подсчёт...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
