import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-entrance-new-password',
  templateUrl: './entrance-new-password.component.html',
  styleUrls: ['./entrance-new-password.component.css']
})
export class EntranceNewPasswordComponent implements OnInit {
  public coincide: boolean = false;

  constructor(public http: HttpClient, private router: Router, private appComponent: AppComponent) {
  }

  ngOnInit(): void {
  }

  public changePassword(password: string): void {
    this.appComponent.loading = true;
    this.http.get('/Account/ChangePassword?password=' + password).subscribe((x) => {
      this.appComponent.updateLogedInStatus();
      this.router.navigate(['/users-list']);
      this.appComponent.loading = false
    }, error => this.appComponent.loading = false);
  }

  public compare(passwordF: string, passwordS: string) {
    if (passwordF == passwordS) this.coincide = true;
    else if (passwordF == "") this.coincide = true;
    else this.coincide = false;
  }
}
