import {Component, OnInit} from '@angular/core';
import {AppComponent} from 'src/app/app.component';
import {LocalStorageService} from 'src/app/local-storage.service';
import {SignalRService} from 'src/app/signal-r.service';

@Component({
  selector: 'app-connecting-signal-r',
  templateUrl: './connecting-signal-r.component.html',
  styleUrls: ['./connecting-signal-r.component.css']
})
export class ConnectingSignalRComponent implements OnInit {
  constructor(private signalRService: SignalRService, private appComponent: AppComponent, private localStorag: LocalStorageService) {
    var logedIn: Boolean = this.localStorag.get("logedIn");
    this.signalRService.onconnecting.subscribe(() => {
      this.loadingText = "Подключение";
    });
    this.signalRService.onconnected.subscribe(() => {
      this.loadingText = "Переподключение";
    });
    this.signalRService.connection.onclose(() => {
      if (logedIn)
        this.loadingText = "Соидинение потеряно";
    });
    this.signalRService.connection.onreconnecting(() => {
      if (logedIn)
        this.loadingText = "Переподключение";
    });
    this.signalRService.connection.onreconnected(() => {
      if (logedIn)
        this.loadingText = "Загрузка";
    });
  }

  loadingText: string = "Загрузка";

  ngOnInit(): void {

  }
}
