<div class="add-organization-popup js-add-organization-popup">
  <div class="add-organization-popup__wrapper">
    <div class="add-organization-popup__title">Создание ключа</div>
    <div class="add-organization-popup__hr"></div>
    <div class="add-organization-popup__block">
      <div class="add-organization-popup__block-inputs">
        <div class="add-organization-popup__selector">
          <div class="card-edit-popup__label">
            Название:
          </div>
          <input #name type="text" class="card-edit-popup__input">
        </div>
        <label>
          <span class="card-edit-popup__label">Описание:</span>
          <input #description type="text" class="card-edit-popup__input">
        </label>
      </div>
    </div>
    <div class="card-edit-popup__bottoms">
      <div class="card-edit-popup__actions">
        <button class="card-edit-popup__removal js-add-organization-removal" mat-dialog-close>Отмена</button>
        <button class="card-edit-popup__conservation js-organization-popup__continue"
                (click)="createIntegrationKey(name.value, description.value)" mat-dialog-close>Добавить
        </button>
      </div>
    </div>
  </div>
</div>
