<div class="entrance">
  <div class="entrance__wrapper" style="text-align: center;">
    <div class="entrance__logo-block">
      <a class="entrance__logo-img">
        <img class="images entrance__logo-img" src="img/logo-big.png" alt="logo">
      </a>
    </div>
    <p>
      {{order.orderNumber}}
    </p>
    <div *ngIf="order.status == 0" class="entrance__text">Не оплачен</div>
    <div *ngIf="order.status == 2" class="entrance__text">Оплачен</div>
    <div *ngIf="order.status == 6" class="entrance__text">Авторизация отклонена</div>
    <!-- <mat-spinner *ngIf="order.status == 0"></mat-spinner> -->
    <mat-icon
      *ngIf="order.status == 2"
      style="
        font-size: 118px;
        margin: 0 auto;
        width: 118px;">check_circle_outline
    </mat-icon>
    <mat-icon
      *ngIf="order.status == 0"
      style="
        font-size: 118px;
        margin: 0 auto;
        width: 118px;">error_outline
    </mat-icon>
    <mat-icon
      *ngIf="order.status == 6"
      style="
        font-size: 118px;
        margin: 0 auto;
        width: 118px;">highlight_off
    </mat-icon>
  </div>
</div>
