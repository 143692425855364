<h2 mat-dialog-title>Условия оплаты заказа на сайте</h2>
<mat-dialog-content class="mat-typography">
  <h3>Способы оплаты:</h3>
  <p>• Наличный расчёт
    Если заказ доставляется курьером, то оплата осуществляется наличными курьеру в руки. При получении заказа
    обязательно проверьте комплектацию заказа и наличие чека.
  </p>
  <p>• Банковской картой
    Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку «Оплата
    заказа банковской картой».</p>
  <h3>
    Оплата происходит через ПАО СБЕРБАНК с использованием Банковских карт следующих платежных систем:
  </h3>
  <p>
    • VISA International
  </p>
  <p>
    • Mastercard Worldwide
  </p>

  <h3>
    Описание процесса передачи данных
  </h3>
  <p>
    Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение с
    платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL.
    В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или
    MasterCard SecureCode для проведения платежа также может потребоваться ввод специального пароля. Настоящий сайт
    поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО
    СБЕРБАНК. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных
    законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями
    платежных систем Visa Int. и MasterCard Europe Sprl.
  </p>

  <h3>
    Описание процессa оплаты
  </h3>
  <p>
    При выборе формы оплаты с помощью пластиковой карты проведение платежа по заказу производится непосредственно после
    его оформления. После завершения оформления заказа в нашем магазине, Вы должны будете нажать на кнопку «Оплата
    банковской картой», при этом система переключит Вас на страницу авторизационного сервера, где Вам будет предложено
    ввести данные пластиковой карты, инициировать ее авторизацию, после чего вернуться в наш магазин кнопкой "Вернуться
    в магазин". После того, как Вы возвращаетесь в наш магазин, система уведомит Вас о результатах авторизации. В случае
    подтверждения авторизации Ваш заказ будет автоматически выполняться в соответствии с заданными Вами условиями. В
    случае отказа в авторизации карты Вы сможете повторить процедуру оплаты.
  </p>

  <h3>
    При аннулировании заказа
  </h3>
  <p>
    При аннулировании позиций из оплаченного заказа (или при аннулировании заказа целиком) Вы можете заказать другой
    товар на эту сумму, либо вернуть всю сумму на карту предварительно написав письмо на e-mail.
  </p>

  <h3>
    Наши реквизиты
  </h3>

  <mat-card style="margin-bottom: 1%;">
    <h3>Название: ИП ТУГУЧЕВ МАКСИМ АНАТОЛЬЕВИЧ</h3>
    <h3>ИНН: 7707083893</h3>
    <h3>ОГРНИП: 7707083893</h3>
    <h3>Наименование банка: ПАО СБЕРБАНК</h3>
    <h3>Номер расчетного счета: 40802810740000041718</h3>
    <h3>Банковские реквизиты: </h3>
    <mat-card>
      <h3>БИК: 044525225</h3>
      <h3>Кор.счет: 30101810400000000225</h3>
    </mat-card>
  </mat-card>


</mat-dialog-content>
<mat-dialog-actions align="center">
  <img src="/img/mir.svg" class="emiteer-icon" alt="">
  <img src="/img/logo-mastercard.svg" class="emiteer-icon" alt="">
  <img src="/img/visa.svg" class="emiteer-icon" alt="">
</mat-dialog-actions>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Закрыть</button>
  <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
