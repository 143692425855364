import {Component, Inject, OnInit} from '@angular/core';
import {ShopUsersService} from "../shop-users.service";
import {UserPublicData} from "../../models/user.model";
import {HttpClient} from "@angular/common/http";
import {OrderService} from "../../order/service/order.service";
import {userInfo} from "os";
import {Observable} from "rxjs";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import * as http from "http";
import {response} from "express";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

//import {AddGuestComponent} from "./add-guest/add-guest.component";

@Component({
  selector: 'app-shop-users',
  templateUrl: './shop-users.component.html',
  styleUrls: ['./shop-users.component.css']
})
export class ShopUsersComponent implements OnInit {
  public shopUsers: UserPublicDataTable[] = []
  public wasteOfUsers: WasteOfUsers[] = []
  public filteredShopUsers!: Observable<UserPublicDataTable[]>;
  public filteredForSowShopUsers: UserPublicDataTable[] = [];
  public control = new FormControl();

  public checker: boolean = false
  public searchText: string = ''
  public phoneIsVerifiedFilter: boolean = false
  public phoneIsNotVerifiedFilter: boolean = false
  public emailIsVerifiedFilter: boolean = false
  public emailIsNotVerifiedFilter: boolean = false

  constructor(
    private http: HttpClient,
    public shopUsersService: ShopUsersService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.http.get<UserPublicDataTable[]>('/Account/DataAboutAllShopAccounts').subscribe(shopUsers => {
      this.shopUsers = shopUsers

      this._filter(this.searchText);

      this.http.post<WasteOfUsers[]>('/api/AquaringSber/AmountOfPurchases', shopUsers).subscribe(wasteOfUsers => {
        this.wasteOfUsers = wasteOfUsers
        this.wasteOfUsers.forEach(x => {
          let user = this.filteredForSowShopUsers.find(usr => usr.id == x.userId)
          if (user) user.sum = this.toTrueFormat(x.amount.toString());
        })
      })
    })

    this.filteredShopUsers = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  public toTrueFormat(amount: string): string {
    var b = ".";
    var position = -2;
    var output = [amount.slice(0, position), b, amount.slice(position)].join('');
    return output;
  }

  checkAll() {
    this.filteredForSowShopUsers.forEach(x => x.checked = this.checker)
  }

  unCheckAll() {
    this.filteredForSowShopUsers.forEach(x => x.checked = false)
  }

  public verifyPhones() {
    let idList: number[] = []
    this.shopUsers.forEach(user => {
      if (user.checked) idList.push(user.id)
    })
    this.http.post('/Account/VerifyPhones', idList).subscribe(() => {
      this.unCheckAll();
    });
  }

  public _filter(value: string): UserPublicDataTable[] {
    const filterValue = this._normalizeValue(value);
    let result = this.shopUsers.filter(x => this._normalizeValue(x.phone).includes(filterValue)
      || this._normalizeValue(x.firstName).includes(filterValue)
      || this._normalizeValue(x.lastName).includes(filterValue)
      || this._normalizeValue(x.patronymic).includes(filterValue)
      || this._normalizeValue(x.email).includes(filterValue));
    this.filter(result)
    return result;
  }

  private filter(result: UserPublicDataTable[]) {
    if (this.phoneIsVerifiedFilter) result = result.filter(x => x.phoneIsVerifed == true)
    if (this.phoneIsNotVerifiedFilter) result = result.filter(x => x.phoneIsVerifed == false)
    if (this.emailIsVerifiedFilter) result = result.filter(x => x.emailIsVerifed == true)
    if (this.emailIsNotVerifiedFilter) result = result.filter(x => x.emailIsVerifed == false)
    this.filteredForSowShopUsers = result;
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  setTemporaryPassword() {
    let idList: number[] = []
    this.shopUsers.forEach(user => {
      if (user.checked) idList.push(user.id)
    })
    this.http.post<string[]>('/Account/ResetPasswordByListId', idList).subscribe((response) => {
        const dialogRef = this.dialog.open(ShopUserDialogDialog, {
          data: response,
        });
      }
    );

  }

  addGuest() {
    //  this.dialog.open(AddGuestComponent);
  }
}

@Component({
  selector: 'dialog-shop-user',
  templateUrl: 'dialog-shop-user.html',
})
export class ShopUserDialogDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string[]) {
  }

}

interface WasteOfUsers {
  userId: number;
  amount: number;
}

interface UserPublicDataTable extends UserPublicData {
  checked: boolean
  sum: string
}
