import {getLocaleDateTimeFormat} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../local-storage.service';
import {User, UserPublicData} from '../models/user.model';
import {Token} from '../models/token.model';
import {Roles} from '../models/roles.moel';
import {CountdownComponent, CountdownConfig, CountdownEvent} from 'ngx-countdown';
import {SignalRService} from '../signal-r.service';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-entrance-sms',
  templateUrl: './entrance-sms.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

  styleUrls: ['./entrance-sms.component.css']
})
export class EntranceSmsComponent implements OnInit {
  token!: Token;
  date!: Date;
  user: UserPublicData = {
    id: 0,
    organizationId: 0,
    role: "",
    claimedRole: "",
    firstName: "",
    lastName: "",
    patronymic: "",
    phone: "",
    email: "",
    avatar: "",
    phoneIsVerifed: false,
    emailIsVerifed: false
  };
  time!: number;
  activeButton: boolean = true;
  callAuth: boolean = false;
  // config!: CountdownConfig;
  @ViewChild('cd', {static: false}) private countdown!: CountdownComponent;


  constructor(private route: ActivatedRoute, private router: Router, public http: HttpClient, public localStorag: LocalStorageService, private changeDetectorRef: ChangeDetectorRef, private signalR: SignalRService, private appComponent: AppComponent) {
  }

  config: CountdownConfig = {notify: [1, 91]};
  notify = '';

  handleEvent(e: CountdownEvent) {
    this.notify = e.action.toUpperCase();
    if (e.action === 'notify') {
      this.notify += ` - ${e.left} ms`;
      this.activeButton = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnInit(): void {
    if (this.localStorag.get('callAuth')) this.callAuth = true;
    this.user = this.localStorag.get("user");
    this.user.phone = this.localStorag.get("phone");
    this.config.format = 'm:ss'
    this.config.leftTime = 90;
    this.countdown.begin();
  }

  onDigitInput(event: any) {

    let element;
    if (event.code !== 'Backspace')
      element = event.srcElement.nextElementSibling;

    if (event.code === 'Backspace')
      element = event.srcElement.previousElementSibling;

    if (element == null)
      return;
    else
      element.focus();
  }

  public TokenBySms(code: string): void {
    this.appComponent.loading = true;
    if (this.user.phone != null)
      if (this.localStorag.get("sms") == "login") {
        this.http.get<Token>('/Account/TokenBySms?phone=' + this.user.phone + '&smsCode=' + code, {}).subscribe((result) => {
          this.token = result;
          this.localStorag.set("access_token", this.token.access_token);
          this.localStorag.set("email", this.token.email);
          this.localStorag.set("username", this.token.username);
          this.localStorag.set("role", this.token.role);
          this.localStorag.set("logedIn", true);
          this.http.get<UserPublicData>("/Account/DataAboutMyAccount").subscribe((result) => {
            this.localStorag.set("user", result);
            this.signalR.connect();
            this.user = result;
            this.appComponent.logedId = true;
            this.router.navigate(['/entrance-new-password']);
            this.appComponent.loading = false;
          }, error => this.appComponent.loading = false);
          this.appComponent.loading = false;
        }, error => {
          this.appComponent.loading = false;
        });
        // if(this.localStorag.get("FirstLoad") == true){
        //   this.localStorag.set("FirstLoad", false);
        //   window.location.reload();
        // }
      } else if (localStorage.getItem("sms") == "verefication") {
        this.http.get<Token>('/Account/ConfirmSMS?smsVereficationCode=' + code, {}).subscribe((result) => {
          this.http.get<UserPublicData>("/Account/DataAboutMyAccount", {}).subscribe((result) => {
            this.localStorag.set("user", result);
            if (result.role != Roles.Candidate) {
              this.router.navigate(['']);
            } else {
              this.router.navigate(['/waiting-for-submit']);
            }
            this.appComponent.updateLogedInStatus();
            this.appComponent.loading = false;
          });
        }, error => {
          this.appComponent.loading = false;
        });
      }
    this.appComponent.updateLogedInStatus();
  }

  public async resendConfirmSMS() {
    await this.http.get("/Account/ResendConfirmSMSByNumber?phone=" + this.user.phone).subscribe(() => {
    });
    this.countdown.restart();
    this.activeButton = true;
  }
}
