import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {QrPayment} from 'src/app/models/paymentData.model';
import {AddQrCodeService} from './add-qr-code.service';

@Component({
  selector: 'app-add-qr-code',
  templateUrl: './add-qr-code.component.html',
  styleUrls: ['./add-qr-code.component.css']
})
export class AddQrCodeComponent extends AddQrCodeService implements OnInit {
  constructor(protected http: HttpClient) {
    super(http);
  }

  ngOnInit(): void {
  }
}
