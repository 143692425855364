<mat-progress-bar mode="query" *ngIf="tabs.length == 0"></mat-progress-bar> <!--*ngIf="tabs.length == 0"-->
<div class="users-list">
  <div class="users-list__wrapper">
    <div class="container">
      <div class="users-list__top">
        <div class="users-list__top-main">
          <div class="users-list__title">Пользователи и компании</div>
          <div class="users-list__line"></div>
          <div class="users-list__search-block">
            <div class="users-list__search-img-block">
              <svg>
                <use xlink:href="img/icons/icons.svg#lupa"></use>
              </svg>
            </div>
            <input
              class="users-list__search-input"
              type="text"
              placeholder="Поиск по имени или организации"
              [formControl]="control"
              [matAutocomplete]="auto">
            <!-- <input class="users-list__search-input" type="text" placeholder="Поиск"> -->
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option style="display: none;" *ngFor="let x of filteredStreets | async" [value]="x.name">
                {{x.name}}
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="users-list__add" *ngIf="role == 'SeniorSecurityOfficer' || role == 'Administrator'"
             [matMenuTriggerFor]="aboveMenu">
          <div class="select js-selector-add ">
            <input class="select__input" type="hidden" name="">
            <div class="select__head">
              <div class="select__header-img-block">
                <img class=" images select__header-img" src="img/plus-small-add-white.svg" alt="">
              </div>
              <div class="select__header-text">
                Добавить
              </div>
              <mat-menu #aboveMenu="matMenu" yPosition="above">
                <button mat-menu-item (click)="addOrganizationPopupDialog()" class="button-add-list">
                  <mat-icon>groups</mat-icon>
                  Организацию
                </button>
                <button mat-menu-item disabled class="button-add-list">
                  <mat-icon>person</mat-icon>
                  Пользователя
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
      <mat-tab-group mat-align-tabs="start">
        <mat-tab *ngFor="let tab of tabs" label="{{tab.name}}">
          <div class="users-list__blocks-companies" *ngFor="let organization of tab.organizations">
            <div class="users-list__blocks-companies-top">
              <div class="users-list__blocks-title" (click)="editOrganizationPopupDialog(organization)">
                <div class="users-list__blocks-forimg">
                  <img class=" images users-list__blocks-img" src="{{organization.imgUrl}}" style="border-radius: 3px;"
                       alt="icon">
                </div>
                <div class="users-list__blocks-companies-title">
                  {{organization.form}} {{organization.name}}
                </div>
              </div>
              <div class="users-list__blocks-add js-add-collaborate" (click)="openDialog(organization.id)">
                <div class="users-list__blocks-images">
                  <img class="images users-list__blocks-img" src="img/plus-small-add.svg" alt="">
                </div>
                <div class="users-list__blocks-text">
                  Добавить сотрудника
                </div>
              </div>
            </div>
            <div class="users-list__items-noscroll">
              <div class="users-list__items">
                <div class="js-users-list__block-item" *ngFor="let user of organization.users"
                     [ngClass]="{
                            'users-list__block-item-active' : user.role != 'Candidate',
                            'users-list__block-item-wait' : user.firstActivation == true,
                            'users-list__block-item' : user.role == 'Candidate'}">
                  <div class="users-list__block-who" (click)="CardUserPopupDialog(user, tab.organizations)">
                    <img class="users-list__block-img-lock" src="img/users-list__block-lock-person.png" alt="lock"
                         *ngIf="user.role == 'SeniorSecurityOfficer' || user.role == 'Administrator' || user.role == 'SeniorManager'">

                    <img class=" images users-list__block-img" src="{{user.avatar}}" alt="person">
                  </div>
                  <div class="users-list__block-name">{{user.firstName}}</div>
                  <div class="users-list__block-surname">{{user.lastName}}</div>
                  <a class="users-list__block-request" (click)="passByUserId(user.id)" matTooltip="Просмотреть заяаки">
                    заяв: {{user.passQuantity}}
                  </a>
                  <div style="margin-top: 5px;">
                    <mat-slide-toggle
                      *ngIf="role == 'SeniorSecurityOfficer' && user.id != myUserData.id"
                      [checked]="user.role != 'Candidate'"
                      (click)="activate(user)">
                      <!-- <span *ngIf="data.user.isActive == true">
                      Активирован
                      </span>
                      <span *ngIf="data.user.isActive == false">
                          Ограничен
                      </span> -->
                    </mat-slide-toggle>
                    <a href="tel:+{{user.phone}}"
                       [ngClass]="{
                                            'users-list__block-phone-lock-active' : user.phoneIsVerifed == true,
                                            'users-list__block-phone-lock' : user.phoneIsVerifed == false}">
                      <mat-icon style="font-size: 15px; margin-top: 4.5px;">phone</mat-icon>
                    </a>
                  </div>

                </div>
                <div class="users-list__block-item-add users-list__block-item-fotplus js-add-collaborate"
                     (click)="openDialog(organization.id)">
                  <div class="users-list__block-who users-list__block-forplus">
                    <img class="images users-list__block-img" src="img/plusssssbig.png" alt="plus">
                  </div>
                  <div class="users-list__block-spec">Добавить</div>
                  <div class="users-list__block-spec">сотрудника</div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
