import {HttpClient} from '@angular/common/http';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {json} from 'express';
import moment from 'moment';
import {retry} from 'rxjs/operators';
import {LocalStorageService} from 'src/app/local-storage.service';
import {Order} from 'src/app/models/order.models';
import {OrderService} from '../service/order.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent extends OrderService implements OnInit, OnDestroy {

  constructor(
    protected http: HttpClient,
    private ref: ChangeDetectorRef,
    public fb: FormBuilder,
    private localStorag: LocalStorageService
  ) {
    super(http);

    this.paymentFilter = fb.group({
      notPaid: false,
      paid: true,
      rejected: false,
      awaitingConfirmation: false,
      returned: false,
    });
    // if (localStorage.getItem('paymentFilter') != null){
    //   var filter = localStorag.get('paymentFilter');
    //   this.paymentFilter = filter;
    // }
  }

  ngOnDestroy(): void {

  }

  public paymentFilter: FormGroup;
  public notPayed: boolean = false;
  public payed: boolean = true;

  public getFromPaymentFilter(value: string): any | null {
    return this.paymentFilter.get(value)?.value;
  }

  ngOnInit(): void {
    this.http.get<Order[]>('/api/Payment/GetAllOrders').subscribe(response => {
      this.orders = response;
      this.ordersNotChanged = response;
      // this.applyFilter();
      console.log(this.orders);
    });
  }

  public getDate(date: Date): string {
    return moment(date).format('HH:mm DD-MM-YYYY')
  }

  public saveFilerParameters() {
    this.localStorag.set('paymentFilter', this.paymentFilter.getRawValue());
    console.log(this.paymentFilter);
  }
}
