import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.css']
})
export class EmailConfirmationComponent implements OnInit {

  constructor(private route: ActivatedRoute, public http: HttpClient) {
  }

  private code: string = "";
  public confirmed: string = "0";

  async ngOnInit() {
    this.code = this.route.snapshot.params['id'];
    await this.http.get<string>('/Account/ConfirmEmail?emailVereficationCode=' + this.code).subscribe(result => {
        this.confirmed = "200";
        console.log(result);
      },
      (error) => {
        this.confirmed = error.status.toString();
      });
  }
}
