<div class="security-page">
  <div class="security-page__wrapper">
    <div class="security-page__container">
      <div class="users-list__top">
        <div class="users-list__top-main">
          <div class="users-list__title">История оплат</div>
          <div class="users-list__line"></div>

        </div>
      </div>
      <div class="example-section" [formGroup]="paymentFilter">
        <!--            <h4>Select your toppings:</h4>-->
        <div>
          <mat-checkbox formControlName="notPaid" class="filter-item">Неоплачен</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="paid" class="filter-item">Оплачен</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="rejected" class="filter-item">Отклонен</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="awaitingConfirmation" class="filter-item">Ожидает подтверждения</mat-checkbox>
        </div>
        <div>
          <mat-checkbox formControlName="returned" class="filter-item">Возвращен</mat-checkbox>
        </div>
      </div>

      <div class="security-page__main">
        <div id="all" class="tabcontent tabcontent-all js-tabcontent  " style="display: block">
          <div class="security-page__all table-orders">
            <div class="security-page__all-top my-page__all-top table-orders__header">
              <div class="security-page__title-category  ">
                Статус
              </div>
              <div class="security-page__title-category  ">
                Сумма
              </div>
              <div class="security-page__title-category  ">
                Описание
              </div>
              <div class="security-page__title-category  ">
                Оплачен
              </div>
              <div class="security-page__title-category  ">
                ФИО
              </div>
              <div class="security-page__title-category  ">
                Возвращены
              </div>
              <div class="security-page__title-category  ">
                Создан
              </div>
            </div>
            <div class="security-page__item  pedestrian-group table-orders__row" *ngFor="let order of orders"
                 [ngClass]="{
                            'pedestrian-group' : order.status == 6,
                            'truck-car-group' : order.status == 2,
                            'wait-confirmation' : order.status == 5,
                            'car-group' : order.status == 0}"
                 [style.display]="
                              (getFromPaymentFilter('notPaid') && order.status == 0) ||
                              (getFromPaymentFilter('paid') && order.status == 2)||
                              (getFromPaymentFilter('rejected') && order.status == 6)||
                              (getFromPaymentFilter('awaitingConfirmation') && order.status == 5)||
                              (getFromPaymentFilter('returned') && order.status == 4)
                              ?'flex':'none'">
              <div class="security-page__info-item table-orders__col">
                <div class="security-page__id">
                  <p *ngIf="order.status == 0">Неоплачен</p>
                  <p *ngIf="order.status == 2">Оплачен</p>
                  <p *ngIf="order.status == 6">Отклонен</p>
                  <p *ngIf="order.status == 5">Ожидает подтверждения</p>
                  <p *ngIf="order.status == 4">Возвращен</p>
                </div>
              </div>
              <div class="security-page__guest table-orders__col">
                <div class="security-page__info-name-person">{{toTrueFormat(order.amount) }} ₽</div>
              </div>

              <div class="security-page__guest table-orders__col">
                <div class="security-page__info-name-person">{{order.description}}</div>
              </div>

              <div class="security-page__guest table-orders__col">
                <div class="security-page__info-name-person">
                  <p *ngIf="order.confirmed">{{order.confirmed |  date:'dd/MM/yyyy HH:mm' }}</p>
                  <p *ngIf="!order.confirmed"> - </p>
                </div>
              </div>

              <div class="security-page__guest table-orders__col">
                <div class="security-page__info-name-person">{{order.name}}</div>
              </div>

              <div class="security-page__guest table-orders__col">
                <div class="security-page__info-name-person">
                  <p *ngIf="order.refounded">{{order.refounded |  date:'dd/MM/yyyy HH:mm' }}</p>
                  <p *ngIf="!order.refounded"> - </p>
                </div>
              </div>

              <div class="security-page__guest table-orders__col">
                <div class="security-page__info-name-person">
                  <p *ngIf="order.created">{{order.created |  date:'dd/MM/yyyy HH:mm' }}</p>
                  <p *ngIf="!order.created"> - </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="security-page__bottom">
              <div class="security-page__bottom-navigation">
                  <div class="security-page__btn-arrow security-page__btn-arrow-preview">
                      <svg>
                          <use xlink:href="img/icons/icons.svg#arrow-nav-bott"></use>
                      </svg>
                  </div>
                  <div class="security-page__btn-location security-page__btn-location-active">
                      1
                  </div>
                  <div class="security-page__btn-location">
                      2
                  </div>
                  <div class="security-page__btn-location">
                      3
                  </div>
                  <div class="security-page__btn-location">
                      4
                  </div>
                  <div class="security-page__btn-arrow security-page__btn-arrow-next security-page__btn-arrow-active">
                      <svg>
                          <use xlink:href="img/icons/icons.svg#arrow-navogation"></use>
                      </svg>
                  </div>
              </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
