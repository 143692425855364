<div class="security-page__arrive-details-wrapper" [formGroup]="form">
  <div class="security-page__arrive-id">{{data.thePurposeOfTheVisit}}</div>
  <div class="security-page__arrive-time">
    <div class="security-page__arrive-hours">{{now.getUTCHours() + 3}}</div>
    <div class="security-page__arrive-time-dots">:</div>
    <div class="security-page__arrive-min">{{now.getMinutes()}}</div>
    <div class="security-page__arrive-time-dots">:</div>
    <div class="security-page__arrive-sec">{{now.getSeconds()}}</div>
  </div>
  <input #rfId type="text" class="security-page__arrive-input b2px46" placeholder="№ пропуска">
  <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                           formControlName="type"
                           *ngIf="data.type !== 'Пешком'"
                           style="margin-bottom: 10px;"
  >
    <mat-button-toggle value="Легковой автомобиль" style="width: 100%; justify-content: center; justify-items: center;">
      <mat-icon style="width: 100%;">drive_eta</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="Грузовой автомобиль">
      <mat-icon>local_shipping</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div class="security-page__arrive-btn" (click)="arrive(rfId.value)" mat-dialog-close>Гость приехал</div>
</div>
