import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {extend} from 'lodash';
import {ProductService} from '../service/product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent extends ProductService implements OnInit {

  constructor(protected http: HttpClient) {
    super(http);
  }

  displayedColumns: string[] = ['name', 'sKU', 'price', "description"];

  ngOnInit(): void {
    //this.addProduct();
    this.getProducts();
  }

}
