import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NotificationsForSend} from '../models/notifications.model';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
  public notifications!: NotificationsForSend;

  constructor(public http: HttpClient) {
  }

  ngOnInit(): void {
    this.http.get<NotificationsForSend>('/Notifications/GetNotifications').subscribe(notifications => {
      this.notifications = notifications;
      console.log(notifications);
    });
  }

  saveChanges() {
    this.http.post('/Notifications/SaveChanges', this.notifications).subscribe();
  }

}
