<div class="entrance entrance-new-password">
  <div class="entrance__wrapper ">
    <div class="entrance__logo-block">
      <a class="entrance__logo-img" href="#">
        <img class="images entrance__logo-img" src="img/logo-big.png" alt="logo">
      </a>
    </div>
    <div class="entrance__text entrance-new-password__title">Придумайте новый пароль</div>
    <div class="entrance__block-form">
      <div class="entrance-new-password__text entrance-new-password__text">Придумайте новый пароль</div>
      <form class="entrance__form" action="">
        <input #passwordF type="password" class="entrance__input  js-entrance__input shadow" required=""
               placeholder="Новый пароль" (input)="compare(passwordF.value, passwordS.value)">
        <input #passwordS type="password" class="entrance__input entrance-new-password__input entrance__input-remind"
               required="" placeholder="Повторите пароль" (input)="compare(passwordF.value, passwordS.value)">

        <button class="entrance__button"
                [ngClass]="{'entrance__button-notActive' : !coincide}"
                [disabled]="!coincide"
                (click)="changePassword(passwordF.value)">
          Войти
        </button>
      </form>
    </div>
  </div>
</div>
