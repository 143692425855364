import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SignalRService} from 'src/app/signal-r.service';
import {PaymentQrServiceService} from './payment-qr-service.service';

@Component({
  selector: 'app-payment-qr',
  templateUrl: './payment-qr.component.html',
  styleUrls: ['./payment-qr.component.css']
})
export class PaymentQrComponent extends PaymentQrServiceService implements OnInit {
  constructor(protected http: HttpClient, protected dialog: MatDialog, protected signalr: SignalRService, protected _snackBar: MatSnackBar) {
    super(http, dialog, signalr, _snackBar);
  }

  ngOnInit(): void {

  }

  getDateStrring(date: Date): string {
    let dateNew = new Date(date);
    let dateString = dateNew.getDay().toString() + '/' + dateNew.getMonth().toString() + '/' + dateNew.getFullYear().toString();
    return dateString;
  }
}
