import {Component, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {LocalStorageService} from '../local-storage.service';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Roles} from '../models/roles.moel';
import {SignalRService} from '../signal-r.service';
import {HubConnectionState} from '@microsoft/signalr';
import {AppComponent} from '../app.component';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private localStorage: LocalStorageService, private router: Router, private _snackBar: MatSnackBar, private signalr: SignalRService, private appComponent: AppComponent) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.localStorage.get("access_token")}`
      }
    });
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
          if (response.status === 401) {
            localStorage.clear();
            this.signalr.connection.stop().then(() => {
              if (localStorage.getItem("FirstLoad") == null) {
                this.localStorage.set("FirstLoad", true);
              }
              var phone: string = this.localStorage.get("phone");
              var firstLoad: boolean = this.localStorage.get("FirstLoad");
              this.signalr.connection.off("SendPrivateUserGet");
              this.signalr.connection.off("IntegrationKeyGet");
              this.signalr.connection.off("CheckpointPassGet");
              this.signalr.connection.off("OrganizationGet");
              this.signalr.connection.off("UserForOrganizationGet");
              this.localStorage.set("logedIn", false);
              this.localStorage.set("role", Roles.Guest);
              this.localStorage.set("FirstLoad", firstLoad);
              this.localStorage.set("phone", phone);
              this.appComponent.updateLogedInStatus();
              this.router.navigate(['/entrance']);
              this.appComponent.loading = false;
              window.location.href = "/entrance";
            });
          } else if (response.status == 400) {
            this.openSnackBar(response.error)
          }
          return throwError(response);
        }
      ));
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "Скрыть",
      {
        duration: 3000
      });
  }
}

// @Component({
//   selector: 'snack-bar-component-example-snack',
//   templateUrl: 'snack-bar-component-example-snack.html',
//   styles: [`
//     .example-pizza-party {
//       color: hotpink;
//     }
//   `],
// })
// export class PizzaPartyComponent {}
