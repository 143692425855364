import {HttpClient, HttpClientModule, HttpResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ReportComponent implements OnInit {

  constructor(public http: HttpClient, private route: Router) {
  }

  private blob: any = null;
  selected!: Date | null;
  date = new FormControl(moment());
  colored: boolean = true;

  ngOnInit(): void {
  }

  downloadFile(carPrice: string, carNightPrice: string, carMoreThan3HoursPrice: string, carMoreThan3HoursNightPrice: string, truckNightPrice: string, truckMoreThan3HoursPrice: string, truckMoreThan3HoursNightPrice: string, truckPrice: string, onFootPrice: string, date: string): void {
    this.http.get("/Export/ExcelReport?carPrice=" + carPrice + "&carNightPrice=" + carNightPrice + "&carMoreThan3HoursPrice=" + carMoreThan3HoursPrice + "&carMoreThan3HoursNightPrice=" + carMoreThan3HoursNightPrice + "&truckNightPrice=" + truckNightPrice + "&truckNightPrice=" + truckNightPrice + "&truckMoreThan3HoursPrice=" + truckMoreThan3HoursPrice + "&truckMoreThan3HoursNightPrice=" + truckMoreThan3HoursNightPrice + "&truckPrice=" + truckPrice + "&onFootPrice=" + onFootPrice + "&date=" + date + "&colored=" + this.colored, {responseType: 'blob' as 'json'}).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', "SPKB_Отчёт_" + date + ".xlsx");
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }
}
