import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {map, startWith} from 'rxjs/operators';
import {Mail} from '../models/mail.models';
import {Roles} from '../models/roles.moel';
import {UserPublicData} from '../models/user.model';

@Component({
  selector: 'app-send-mail-with-choose-user',
  templateUrl: './send-mail-with-choose-user.component.html',
  styleUrls: ['./send-mail-with-choose-user.component.css']
})
export class SendMailWithChooseUserComponent implements OnInit {
  public filteredOptions!: Observable<UserPublicData[]>;
  public filteredUsers!: UserPublicData[];
  public myControl = new FormControl();
  public mail: Mail = {
    subject: "",
    body: "",
    rrecipientUserId: 0
  };
  public users!: UserPublicData[];
  public user: UserPublicData = {
    id: -1,
    firstName: "Имя",
    lastName: "Фамилия",
    patronymic: "Отчество",
    phone: "",
    email: "",
    phoneIsVerifed: false,
    emailIsVerifed: false,
    avatar: "/img/account_circle_black_24dp.svg",
    organizationId: 0,
    claimedRole: Roles.Candidate,
    role: Roles.Candidate
  };

  constructor(public http: HttpClient, private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.http.get<UserPublicData[]>('/Account/DataAboutAllAccounts').subscribe(users => {
      this.users = users;
      // this.user = users[0];
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });

  }

  async sendMail() {
    this.mail.rrecipientUserId = this.user.id;
    this.mail.subject = `${this.user.lastName} ${this.user.firstName} ${this.user.patronymic}`;
    await this.http.post('/Mail/Send', this.mail).subscribe(result => {
      this._snackBar.open("Письмо отправлено");
    });
  }

  public _filter(value: string): UserPublicData[] {
    const filterValue = value.toLowerCase();
    var result = this.users.filter(user =>
      this._normalizeValue(user.email).includes(filterValue)
      || this._normalizeValue(user.phone).includes(filterValue)
      || this._normalizeValue(user.firstName).includes(filterValue)
      || this._normalizeValue(user.lastName).includes(filterValue)
      || this._normalizeValue(user.patronymic).includes(filterValue)
    );
    this.filteredUsers = result;
    return result;
  }

  public chooseUser(id: number) {
    this.user = this.filteredUsers.filter(user => user.id == id)[0];
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }
}
