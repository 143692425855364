<div class="report-pass-popup js-add-pass-popup">
  <div class="add-pass-popup__wrapper">
    <div class="add-pass-popup__top">
      <div class="add-pass-popup__top-block">
        <div class="add-pass-popup__title">График</div>
        <!-- <div class="add-pass-popup__when">от 22 Ареля, 2021</div> -->
      </div>
      <div class="add-pass-popup__exit js-add-pass-popup__exit" mat-dialog-close>
        <svg>
          <use xlink:href="img/icons/icons.svg#exit-cross"></use>
        </svg>
      </div>
    </div>
    <div class="add-pass-popup__bottom">
    </div>
    <div class="add-pass-popup__main">
      <div class="add-pass-popup__info-guest">
        <mat-card class="card-times">
          <div style="position: relative;">
            <mat-checkbox
              *ngIf="!schedule.defaultParameters"
              [(ngModel)]="schedule.isShiftMode" matTooltip="Режим таймера"
              class="timer-schekbox">
              По сменам
            </mat-checkbox>
            <!-- <span class="card-title" *ngIf="schedule.isShiftMode">Смены</span>
            <span class="card-title" *ngIf="!schedule.isShiftMode">Таймер</span> -->
          </div>

          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title" *ngIf="schedule.isShiftMode">Начало первой смены</span>

          <input [ngxMatTimepicker]="picker" class="security-page__arrive-input" placeholder="00:00"
                 *ngIf="schedule.isShiftMode" [disabled]="schedule.defaultParameters"
                 [format]="24"
                 readonly [(ngModel)]="schedule.firstShift">
          <ngx-mat-timepicker #picker></ngx-mat-timepicker>

          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title" *ngIf="schedule.isShiftMode">Начало второй смены</span>
          <input [ngxMatTimepicker]="picker2" class="security-page__arrive-input" placeholder="00:00"
                 *ngIf="schedule.isShiftMode" [disabled]="schedule.defaultParameters"
                 [format]="24"
                 readonly [(ngModel)]="schedule.secondShift">

          <ngx-mat-timepicker #picker2></ngx-mat-timepicker>
          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title" *ngIf="schedule.isShiftMode">Начало третей смены</span>
          <input [ngxMatTimepicker]="picker3" class="security-page__arrive-input" placeholder="00:00"
                 *ngIf="schedule.isShiftMode" [disabled]="schedule.defaultParameters"
                 [format]="24"
                 readonly [(ngModel)]="schedule.thirdShift">

          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title" *ngIf="!schedule.isShiftMode">Продолжительность сесии (часов)</span>
          <input type="number" class="security-page__arrive-input" placeholder="8" *ngIf="!schedule.isShiftMode" min="0"
                 max="24" [disabled]="schedule.defaultParameters"
                 matTooltip="Укажите через сколько часов должна закончится сесия"
                 [(ngModel)]="schedule.timerHours">
          <ngx-mat-timepicker #picker3></ngx-mat-timepicker>

          <div style="position: relative;">
            <mat-checkbox
              (click)="getDefault(schedule.defaultParameters)"
              [(ngModel)]="schedule.defaultParameters"
              style="margin: auto; margin-top: 3%;">
              Стандартные
            </mat-checkbox>
          </div>
        </mat-card>

        <button mat-raised-button style="
                        width: 100%; background-color: #ea5c0a; color: rgb(255, 255, 255); margin-top: 3%;"
                matTooltip="Сохранить" class="mt-3" (click)="saveSettings()">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
