import {HttpClient} from '@angular/common/http';
import {toBase64String} from '@angular/compiler/src/output/source_map';
import {ChangeDetectorRef, Injectable, OnInit} from '@angular/core';
import {response} from 'express';
import {AquaringSber} from 'src/app/models/aquaringSber.models';
import {Order} from 'src/app/models/order.models';
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  cart!: Cart;
  // constructor(protected http: HttpClient, public ref: ChangeDetectorRef, public route: ActivatedRoute) {
  constructor(protected http: HttpClient) {
    this.getAcuaringData();
  }

  // async ngOnInit() {
  //   await this.route.queryParams.subscribe(params => {
  //     if (params['orderId'] != undefined) {
  //       this.http.get('/api/Cart/AddItem?aquaringSberId=' + params['orderId']).subscribe((carItem) => {
  //         this.getCurrentUserCart();
  //         console.log(carItem)
  //         this.ref.detectChanges();
  //         this.ref.markForCheck();
  //       });
  //     } if(sessionStorage.getItem("saved-cart-item") != null){
  //       this.http.get('/api/Cart/AddItem?aquaringSberId=' + sessionStorage.getItem("saved-cart-item"))
  //         .subscribe((carItem) => {
  //           sessionStorage.removeItem("saved-cart-item");
  //           this.getCurrentUserCart();
  //           console.log(carItem)
  //           this.ref.detectChanges();
  //           this.ref.markForCheck();
  //         });
  //     } else {
  //       this.getCurrentUserCart();
  //       this.ref.detectChanges();
  //       this.ref.markForCheck();
  //     }
  //   });
  // }

  public orders!: Order[];
  public ordersNotChanged!: Order[];

  public qrAquaringsData: AquaringSber[] = [];
  public qrAcquiring!: QrAcquiringResponse;

  public getQrAcquiring(amount: string, description: string) {
    let amountNumber = Number(amount);
    amountNumber = amountNumber * 100;
    this.http.get<QrAcquiringResponse>('/api/payment/QrAcquiring?amount=' + amountNumber.toString() + "&description=" + description).subscribe(response => {
      this.qrAcquiring = response;
    })
  }

  public getAcuaringData() {
    this.http.get<AquaringSber[]>('/api/AquaringSber').subscribe(response => {
      this.qrAquaringsData = response;
    });
  }

  public toTrueFormat(amount: string): string {
    var b = ".";
    var position = -2;
    var output = [amount.slice(0, position), b, amount.slice(position)].join('');
    return output;
  }

  // getCurrentUserCart(){
  //   this.http.get<Cart>("/api/Cart/GetCurrentUserCart").subscribe(response => {
  //     this.cart = response;
  //     console.log("getCurrentUserCart() is activated")
  //     console.log(response);
  //     this.pay();
  //     this.ref.detectChanges();
  //     this.ref.markForCheck();
  //   }, error => {
  //     this.http.get('/api/Cart/CreateCart').subscribe(() => {
  //       this.getCurrentUserCart();
  //       this.ref.detectChanges();
  //       this.ref.markForCheck();
  //     })
  //   });
  // }
  //
  //
  // public pay(){
  //   console.log("pay() is activated")
  //   this.http.get<QrAcquiringResponse>('/api/Cart/ConfirmCart').subscribe(response => {
  //     //this.router.navigateByUrl(response.formUrl);
  //     console.log('pay: ', response);
  //     window.location.href = response.formUrl;
  //   });
  // }
}

interface QrAcquiringResponse {
  orderId: string;
  formUrl: string;
}

export interface Cart {
  id: number;
  userId: number;
  status: number;
  created: Date;
  confirmed: Date;
  cartItems: CartItem[];
}

export interface CartItem{
  id: number;
  cartId: number;
  aquaringSberId: number;
  aquaringSber: AquaringSber;
  quantity: number;
}
