import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {CheckpointPassCreate} from '../models/checkpointPassCreate.model';
import {PassengerCreate} from '../models/passengerCreate.model';

@Component({
  selector: 'app-add-pass-director-popup',
  templateUrl: './add-pass-director-popup.component.html',
  styleUrls: ['./add-pass-director-popup.component.css']
})
export class AddPassDirectorPopupComponent implements OnInit {
  range = new FormGroup({});
  public myControl = new FormControl();
  public filteredOptions!: Observable<string[]>;
  public checkpointPass!: CheckpointPassCreate;
  public empPassenger!: PassengerCreate;
  public passengers: PassengerCreate[] = [];
  public cars: string[] = ["Kia", "Lada", "Daewoo", "Bugatti", "Toyota", "Lexus", "Audi", "Volkswagen", "Mercedes", "Maserati", "Ford", "Ferrari", "Lamborghini", "Tesla", "Porsche",
    "BMW", "Bentley", "Mazda", "Cherry", "Renault", "Dacia", "Nissan", "Volvo", "Citroen", "Subaru", "Mitsubishi", "Peugeot", "Jeep", "Hyundai", "Honda", "Jaguar", "Suzuki", "Skoda", "ВАЗ", "ГАЗ", "ЗАЗ", "КрАЗ",
    "УАЗ"];
  public purposesOfTheVisit: string[] = ["Курьер", "Доставка еды", "Клиенты для просмотра товара", "Поставщик везет продукцию"];

  constructor(public http: HttpClient, private _fb: FormBuilder) {

  }

  ngOnInit(): void {

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    var empPassenger: PassengerCreate = {
      id: 0,
      userName: "",
      document: "",
      documentType: "Не проверять"
    };
    let start = new Date();
    let end = new Date();
    end.setDate(end.getDate() + 2);
    this.range = this._fb.group({
      start: new FormControl(start),
      end: new FormControl(end),
    })
    console.log(this.passengers.push(empPassenger));
    var empCheckpointPass: CheckpointPassCreate = {
      passengers: this.passengers,
      thePurposeOfTheVisit: "",
      type: "Легковой автомобиль",
      cargo: false,
      governmentNumber: "",
      carBrand: "",
      arrivalFrom: this.range.controls.start.value,
      arrivalTo: this.range.controls.end.value
    };
    this.checkpointPass = empCheckpointPass;
    console.log(this.checkpointPass);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.cars.filter(car => car.toLowerCase().indexOf(filterValue) === 0);
  }

  public createPass(): void {
    this.checkpointPass.arrivalFrom = new Date(Date.UTC(this.range.controls.start.value.getFullYear(), this.range.controls.start.value.getMonth(), this.range.controls.start.value.getDate(), 0, 0, 0));
    this.checkpointPass.arrivalTo = new Date(Date.UTC(this.range.controls.end.value.getFullYear(), this.range.controls.end.value.getMonth(), this.range.controls.end.value.getDate(), 0, 0, 0));

    this.http.post<CheckpointPassCreate>("/CheckpointPass/CreatePass", this.checkpointPass, {}).subscribe((result) => {

    });
  }

  public addGuest(): void {
    if (this.checkpointPass.passengers.length < 4) {
      var empPassenger: PassengerCreate = {
        id: this.checkpointPass.passengers[this.checkpointPass.passengers.length - 1].id + 1,
        userName: "",
        document: "",
        documentType: "В У"
      };
      this.checkpointPass.passengers.push(empPassenger);
    }
    console.log(this.checkpointPass);
  }

  public deleteGuest(id: number): void {
    if (this.checkpointPass.passengers.length > 1) {
      for (var i = 0; i < this.checkpointPass.passengers.length; i++) {
        if (this.checkpointPass.passengers[i].id == id) {
          this.checkpointPass.passengers.splice(i, 1);
        }
      }
    }
    console.log(this.checkpointPass);
  }
}
