<div class="entrance">
  <div class="entrance__wrapper">
    <div class="entrance__logo-block">
      <a class="entrance__logo-img" href="#">
        <img class="images entrance__logo-img" src="img/logo-big.png" alt="logo">
      </a>
    </div>
    <div class="entrance__text entrance-sms__title">
      <span *ngIf="!callAuth">Введите код из SMS</span>
      <span *ngIf="callAuth">Ожидайте звонок</span>
    </div>
    <div class="entrance__block-form">
      <form class="entrance__form" action="">
        <div class="entrance__reform-text entrance-sms__text">
          <span *ngIf="!callAuth">Код отправлен на номер</span>
          <span *ngIf="callAuth">Введите последние 4 цифры номера телефона</span>
          <input name="phoneLog" [(ngModel)]="user.phone" type="tel" prefix="+7 " mask="(000) 000-00-00"
                 class="card-edit-popup__input" style="text-align: center;" required="tel" [showMaskTyped]="true"
                 disabled>
          <img *ngIf="callAuth" src="assets/img/call.png" class="call-img" alt="">
        </div>

        <div style="width: 100%;" class="entrance-sms__inputs">
          <input #code style="width: 100%;" type="text" maxlength="4" size="4"
                 class="entrance__input entrance__input-sms js-entrance__input shadow b2px46" required=""
                 placeholder="Код">
        </div>

        <a class="entrance__button entrance-sms-button" (keydown.enter)="TokenBySms(code.value)"
           (blur)="TokenBySms(code.value)" (click)="TokenBySms(code.value)">Войти</a>
      </form>
      <button style="margin-top: 5%;" mat-button [disabled]="activeButton" (click)="resendConfirmSMS()">
        <!-- Ng-style disabled -->
        Выслать код повторно
        <countdown #cd [config]="config" (event)="handleEvent($event)"></countdown>
      </button>
    </div>
  </div>
</div>
