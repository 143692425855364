<div class="add-pass-popup js-add-pass-popup">
  <div class="add-pass-popup__wrapper">
    <div class="add-pass-popup__top">
      <div class="add-pass-popup__top-block">
        <div class="add-pass-popup__title">Заявка на пропуск</div>
        <!-- <div class="add-pass-popup__when">от 22 Ареля, 2021</div> -->
      </div>
      <div class="add-pass-popup__exit js-add-pass-popup__exit" mat-dialog-close>
        <svg>
          <use xlink:href="img/icons/icons.svg#exit-cross"></use>
        </svg>
      </div>
    </div>
    <div class="add-pass-popup__main">
      <div class="add-pass-popup__info-guest">
        <div class="add-pass-popup__info-guest-title">
          Информация о госте
        </div>

        <div class="add-pass-popup__info-guest-block js-add-pass-popup__info-guest-block"
             *ngFor="let passenger of checkpointPass.passengers">
          <div class="add-pass-popup__info-guest-amount">
            Гость <span class="add-pass-popup__info-guest-number"></span>
          </div>
          <label class="add-pass-popup__label-surname">
            <span class="add-pass-popup__span-title">Фамилия Имя Отчество:</span>
            <input class="add-pass-popup__input-full-name"
                   [ngClass]="{'b2px46': passenger.userName.length != 0, 'b2px46-error': passenger.userName.length == 0}"
                   [(ngModel)]="passenger.userName" placeholder="Фамилия Имя Отчество:" (input)="checkValidInput()">
          </label>
          <div class="add-pass-popup__documents">
            <span class="add-pass-popup__span-title">Документ:</span>
            <mat-select [(ngModel)]="passenger.documentType" class="b2px46">
              <mat-option value="Паспорт" (click)="checkValidInput()">Паспорт</mat-option>
              <mat-option value="Биометрический" (click)="checkValidInput()">Биометрический</mat-option>
              <mat-option value="Водительское удостоверение" (click)="checkValidInput()">Водительское удостоверение
              </mat-option>
            </mat-select>
          </div>
          <label class="add-pass-popup__label-number-doc"
                 *ngIf="passenger.documentType == 'Водительское удостоверение'">
            <input type="text" class="add-pass-popup__number-doc" (input)="checkValidInput()"
                   [(ngModel)]="passenger.document">
          </label>
          <label class="add-pass-popup__label-number-doc" *ngIf="passenger.documentType == 'Биометрический'">
            <input type="text" class="add-pass-popup__number-doc" (input)="checkValidInput()"
                   [(ngModel)]="passenger.document">
          </label>
          <label class="add-pass-popup__label-number-doc" *ngIf="passenger.documentType == 'Паспорт'">
            <input type="text" class="add-pass-popup__number-doc" (input)="checkValidInput()"
                   [(ngModel)]="passenger.document">
          </label>
          <div class="add-pass-popup__info-guest-delete js-add-pass-popup__info-guest-delete"
               [ngClass]="{'b2px46': passenger.document.length != 0, 'b2px46-error': passenger.document.length == 0}"
               *ngIf="checkpointPass.passengers.length != 1" (click)="deleteGuest(passenger.id)">
            <svg>
              <use xlink:href="img/icons/icons.svg#add-pass-popup__info-guest-remove"></use>
            </svg>
          </div>
        </div>
        <div class="add-pass-popup__add-new js-add-pass-popup__add-new" *ngIf="checkpointPass.passengers.length != 4"
             (click)="addGuest()">
          <div class="add-pass-popup__images">
            <svg>
              <use xlink:href="img/icons/icons.svg#add-orange-pluss"></use>
            </svg>
          </div>
          <div class="add-pass-popup__add-text ">
            Добавить гостя
          </div>
        </div>
      </div>
      <div class="add-pass-popup__entry">
        <div class="add-pass-popup__entry-title">Информация о въезде</div>
        <span class="add-pass-popup__span-title">Способ въезда:</span>
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="checkpointPass.type">
          <mat-button-toggle value="Пешком" (click)="checkValidInput()">
            <mat-icon>directions_walk</mat-icon>
            <div class="status">Пешком</div>
          </mat-button-toggle>
          <mat-button-toggle value="Легковой автомобиль" (click)="checkValidInput()">
            <mat-icon>drive_eta</mat-icon>
            <div class="status">Легковой автомобиль</div>
          </mat-button-toggle>
          <mat-button-toggle value="Грузовой автомобиль" (click)="checkValidInput()">
            <mat-icon>local_shipping</mat-icon>
            <div class="status">Грузовой автомобиль</div>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <div *ngIf="checkpointPass.type == 'Легковой автомобиль' || checkpointPass.type == 'Грузовой автомобиль'"
             class="tabcontent js-tabcontent-entry" style="display: block">
          <div class="add-pass-popup__tabcontent">
            <label class="add-pass-popup__label-tab">
              <span class="add-pass-popup__span-title" *ngIf="gosShortNumber.length == 0">
                Гос номер (полный)
                <mat-checkbox
                  [(ngModel)]="withoutMask"
                  (input)="checkValidInput()">
                  Иностранный
                </mat-checkbox>
              </span>
              <input *ngIf="!withoutMask" type="text" mask="V-LLL-VV-LL||V-LLL-VV-LLL"
                     [patterns]="customPatterns" [showMaskTyped]="true" class="add-pass-popup__tab-input"
                     [ngClass]="{'b2px46': checkpointPass.governmentNumber.length > 7, 'b2px46-error': checkpointPass.governmentNumber.length < 8 || checkForPattern(checkpointPass)}"
                     [(ngModel)]="checkpointPass.governmentNumber" (input)="checkValidInput()">
              <input *ngIf="withoutMask" type="text" class="add-pass-popup__tab-input"
                     [(ngModel)]="checkpointPass.governmentNumber" (input)="checkValidInput()">

            </label>
            <!-- mask="9-999-99||9-999-99-99||999" -->
            <div class="add-pass-popup__tab-select">
              <span class="add-pass-popup__span-title">Марка:</span>
              <input type="text"
                     class="add-pass-popup__tab-input ng-pristine ng-valid ng-touched"
                     placeholder="Марка"
                     aria-label="Number"
                     matInput
                     [formControl]="myControl"
                     [matAutocomplete]="auto"
                     [(ngModel)]="checkpointPass.carBrand">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let car of filteredOptions | async" value="{{car}}" [value]="car">
                  {{car}}
                </mat-option>
              </mat-autocomplete>
              <!-- <mat-select [(ngModel)]="checkpointPass.carBrand">
                  <mat-option *ngFor="let car of cars" value="{{car}}">{{car}}</mat-option>
              </mat-select>-->
            </div>
            <label class="add-pass-popup__checkbox">
              <mat-checkbox type="checkbox" class="add-pass-popup__input-checked"
                            [(ngModel)]="checkpointPass.cargo"></mat-checkbox>
              <!-- <span class="add-pass-popup__span-fake"></span> -->
              <span class="add-pass-popup__checkbox-text" style="margin-left: auto;"> Машина с грузом</span>
            </label>
          </div>
          <input  *ngIf="warningGosNumber && !withoutMask" type="text" style="margin-top: 2%;background: #ea5c0a;color: white;"
                 class="add-pass-popup__tab-input b2px46" value="Гос номер не будет распознан автоматически">
          <input  *ngIf="checkForPattern(checkpointPass) && !withoutMask" type="text"
                 style="margin-top: 2%;background: #ea5c0a;color: white;" class="add-pass-popup__tab-input b2px46"
                 value="Только кирилица">
        </div>
      </div>
      <div class="add-pass-popup__purpose" [formGroup]="range">
        <mat-date-range-input [rangePicker]="picker" class="add-pass-popup__number-doc">
          <input matStartDate formControlName="start" placeholder="Start date" [disabled]="true">
          <input matEndDate formControlName="end" placeholder="End date" [disabled]="true">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker disabled="false" #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </div>
      <div class="add-pass-popup__purpose">
        <div class="add-pass-popup__purpose-title">Цель визита</div>
        <!-- <div class="add-pass-popup__purpose-add-text" style="display: inline-flex; align-items: center; position: absolute; right: 10%;">Выбрать цель из списка
               <div class="add-pass-popup__purpose-add-images" style="display: inline-flex; align-content: flex-end;">
                   <img class="add-pass-popup__purpose-add-img" src="img/add-pass-popup__purpose-add-cross.svg" alt="cross">
               </div>
            </div> -->
        <div class="add-pass-popup__purpose-block"
             [ngClass]="{'b2px46': checkpointPass.thePurposeOfTheVisit.length != 0, 'b2px46-error': checkpointPass.thePurposeOfTheVisit.length == 0}">
          <textarea class="add-pass-popup__purpose-textarea" id="" cols="30" rows="10"
                    placeholder="Введите цель визита вашего гостя" [(ngModel)]="checkpointPass.thePurposeOfTheVisit"
                    (input)="checkValidInput()"></textarea>
          <div class="add-pass-popup__purpose-add" [matMenuTriggerFor]="menu">
            <div class="add-pass-popup__purpose-add-images">
              <svg>
                <use xlink:href="img/icons/icons.svg#add-pass-popup__purpose-add-cross"></use>
              </svg>
            </div>
          </div>
          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="checkpointPass.thePurposeOfTheVisit = purposeOfTheVisit; checkValidInput();"
                    *ngFor="let purposeOfTheVisit of purposesOfTheVisit">
              <mat-icon>west</mat-icon>
              <span>{{purposeOfTheVisit}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div class="add-pass-popup__bottom">
      <div class="add-pass-popup__bottom-buttons">
        <button class="add-pass-popup__btn-cancel" mat-dialog-close>Отмена</button>
        <button [disabled]="addButtonDisabled"
                [ngClass]="{
            'add-pass-popup__btn-create-pass-active':!addButtonDisabled,
            'add-pass-popup__btn-create-pass':addButtonDisabled
          }"
                (click)="createPass()" mat-dialog-close>Отправить
        </button>
      </div>
    </div>
  </div>
</div>
