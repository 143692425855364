<h1 mat-dialog-title>Добавить</h1>
<div mat-dialog-content>
  <p>
    <span class="add-pass-popup__span-title">Название</span>
    <input matInput [(ngModel)]="paymentData.name">

    <span class="add-pass-popup__span-title">Сумма ₽</span>
    <input matInput type='text' mask="separator.2" thousandSeparator="" [(ngModel)]="paymentData.amount"/>

    <mat-checkbox class="example-margin" [(ngModel)]="paymentData.floatingCost">
      Покупатель может изменить стоимость
    </mat-checkbox>

    <span class="add-pass-popup__span-title">Описание</span>
    <textarea matInput [(ngModel)]="paymentData.description"></textarea>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Отмена</button>
  <button mat-button mat-dialog-close class="w-100" (click)="addAquaring()">Добавить</button>
</div>
