<div class="security-page__arrive-details-wrapper">
  <div class="security-page__arrive-id"></div>
  <!-- <div class="security-page__arrive-time">

  </div> -->
  <h1 class="head-word">Уведомления</h1>
  <mat-card class="notification-card">
    <mat-checkbox class="example-margin checker" [(ngModel)]="notifications.notifyOnArrival">Уведомлять о приезде
    </mat-checkbox>
    <mat-checkbox class="example-margin checker" [(ngModel)]="notifications.notifyOnDeparture">Уведомлять об отъезде
    </mat-checkbox>
    <mat-checkbox class="example-margin checker" [(ngModel)]="notifications.notifyAboutLate">Уведомлять о просрочке
    </mat-checkbox>
  </mat-card>
  <div class="security-page__arrive-btn" (click)="saveChanges()" mat-dialog-close>
    <mat-icon>save</mat-icon>
  </div>
</div>
