import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ConditionsComponent} from '../conditions/conditions.component';

@Component({
  selector: 'app-conditions-page',
  templateUrl: './conditions-page.component.html',
  styleUrls: ['./conditions-page.component.css']
})
export class ConditionsPageComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private router: Router
  ) {
    const dialogRef = this.dialog.open(ConditionsComponent);
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate([".."]);
    });
  }

  ngOnInit(): void {
  }

}
