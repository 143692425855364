<div class="card-user-popup js-card-user-popup" style="max-width: 90%!important;">
  <div class="card-user-popup__wrapper">
    <div class="card-user-popup__top">
      <div class="card-user-popup__person-info">
        <div class="card-user-popup__person-img-block">
          <img class=" images card-user-popup__person-img" src="{{user.avatar}}" alt="person">
        </div>
        <div class="card-user-popup__full-name">
          {{user.lastName}} {{user.firstName}} {{user.patronymic}}
        </div>
      </div>
      <div class="card-user-popup__other button-groupe-send-mail">
        <a class=" card-user-popup__round card-user-popup__edit js-card-user-popup__edit" style="color: #464646;"
           href="tel:+7{{user.phone}}"
           [ngStyle]="{'border-bottom': user.phoneIsVerifed ? '2px solid #ef874b' : '2px solid #bbbbbb' }">
          <mat-icon class="card-user-popup__edit-img">phone</mat-icon>
        </a>
        <a class=" card-user-popup__round card-user-popup__edit js-card-user-popup__edit" style="color: #464646;"
           href="mailto:{{user.email}}"
           [ngStyle]="{'border-bottom': user.emailIsVerifed ? '2px solid #ef874b' : '2px solid #bbbbbb' }">
          <mat-icon class="card-user-popup__edit-img">alternate_email</mat-icon>
        </a>
        <div class=" card-user-popup__round card-user-popup__edit js-card-user-popup__edit" (click)="sendMail()"
             mat-dialog-close style="border-bottom: 2px solid #bbbbbb">
          <mat-icon class="card-user-popup__edit-img" style="color: #ef8041;">send</mat-icon>
        </div>
        <div class="card-user-popup__round card-user-popup__close js-card-user-popup__close" mat-dialog-close
             style="border-bottom: 2px solid #bbbbbb">
          <img class="card-user-popup__close-img" src="img/close-popup.svg" alt="close">
        </div>
      </div>
    </div>
    <div class="card-user-popup__hr"></div>
    <mat-card style="margin-left: 2%; margin-right: 2%;">
      <input
        class="users-list__search-input"
        style="width: 100%; max-width: none;"
        type="text"
        placeholder="Поиск по имени почте или номеру телефона"
        [formControl]="myControl"
        [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.email"
                    (click)="chooseUser(option.id)">
          {{option.lastName}} {{option.firstName}} {{option.patronymic}}   |   {{option.email}}
        </mat-option>
      </mat-autocomplete>
    </mat-card>

    <div class="card-user-popup__hr"></div>
    <div class="card-user-popup__info">
      <div class="card-user-popup__title">Письмо</div>
      <div class="card-user-popup__contacts">
        <textarea class="add-pass-popup__purpose-textarea text-area" [(ngModel)]="mail.body" id="" cols="30" rows="10"
                  placeholder="Введите сообщение"></textarea>
      </div>
    </div>
  </div>
</div>
