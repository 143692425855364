import {CurrencyPipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ConditionsComponent} from '../conditions/conditions.component';
import {OrderService} from '../service/order.service';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.css']
})
export class PayComponent extends OrderService implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected http: HttpClient,
    private currencyPipe: CurrencyPipe,
    public dialog: MatDialog
  ) {
    super(http);
  }

  public amount: string = "";
  public description: string = "";
  public aquaringSberId: string = "";
  public acceptCondition: boolean = true;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let amount = params['amount'];
      let description = params['description'];
      let aquaringSberId = params['aquaringSberId'];

      this.amount = amount.toString();
      this.description = description.toString();
      this.aquaringSberId = aquaringSberId.toString();

      console.log(this.amount);
      console.log(this.description);
    });
  }

  public redirectToSber() {
    this.http.get<QrAcquiringResponse>('/api/payment/QrAcquiring?amount=' + this.amount + "&description=" + this.description + "&aquaringSberId=" + this.aquaringSberId).subscribe(response => {
      //this.router.navigateByUrl(response.formUrl);
      window.location.href = response.formUrl;
    });
  }

  public openConditionsDialog() {
    const dialogRef = this.dialog.open(ConditionsComponent);
  }
}

interface QrAcquiringResponse {
  orderId: string;
  formUrl: string;
}
