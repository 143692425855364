import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {result} from 'lodash';
import {AppSettings} from '../models/appSettings.models';

@Component({
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.css']
})
export class ApplicationSettingsComponent implements OnInit {
  public appSettings!: AppSettings;

  constructor(private http: HttpClient, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.http.get<AppSettings>('/AppConfiguration/GetSettings').subscribe(settings => {
      this.appSettings = settings;
    });
  }

  public saveSettings() {
    this.http.post('/AppConfiguration/SaveChanges', this.appSettings, {}).subscribe(result => {
      this.dialog.closeAll();
    });
  }

  public reconnectToSignalR() {
    this.http.get('/AppConfiguration/ReconnectToSignalR').subscribe();
  }

  public shutdown() {
    this.http.get('/AppConfiguration/Shutdown').subscribe();
  }
}
