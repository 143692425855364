<div class="entrance">
  <div class="entrance__wrapper" style="text-align: center;">
    <div class="entrance__logo-block">
      <a class="entrance__logo-img">
        <img class="images entrance__logo-img" src="img/logo-big.png" alt="logo">
      </a>
    </div>
    <div class="entrance__text">Ожидайте подтверждения</div>
    <mat-icon style="
        font-size: 118px;
        margin: 0 auto;
        width: 118px;">schedule
    </mat-icon>
  </div>
</div>
