<!-- <div class="add-organization-popup js-add-organization-popup">
    <div class="add-organization-popup__wrapper">
        <div class="add-organization-popup__title">Добавление организации</div>
        <div class="add-organization-popup__hr"></div>
        <div class="add-organization-popup__block">
            <input #dateM matInput [matDatepicker]="dp" [formControl]="date">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            style="background-color: teal!important;"
                            startView="multi-year"
                            (yearSelected)="chosenYearHandler($event)"
                            (monthSelected)="chosenMonthHandler($event, dp)"
                            panelClass="example-month-picker">
            </mat-datepicker>
          </mat-card>
        <span class="add-pass-popup__span-title">Пешком ₽</span>
        <input #onFoot type="number" class="security-page__arrive-input" placeholder="Тариф пешехода" value="0">
        <span class="add-pass-popup__span-title">Легковой автомобиль ₽</span>
        <input #car type="number" class="security-page__arrive-input" placeholder="Тариф легкового автомобиля" value="100">
        <span class="add-pass-popup__span-title">Грузовой автомобиль ₽</span>
        <input #truck type="number" class="security-page__arrive-input" placeholder="Тариф грузового автомобиля" value="150">
        </div>

    </div>
</div>
 -->


<div class="report-pass-popup js-add-pass-popup">
  <div class="add-pass-popup__wrapper">
    <div class="add-pass-popup__top">
      <div class="add-pass-popup__top-block">
        <div class="add-pass-popup__title">Отчёт</div>
        <!-- <div class="add-pass-popup__when">от 22 Ареля, 2021</div> -->
      </div>
      <div class="add-pass-popup__exit js-add-pass-popup__exit" mat-dialog-close>
        <svg>
          <use xlink:href="img/icons/icons.svg#exit-cross"></use>
        </svg>
      </div>
    </div>
    <div class="add-pass-popup__bottom">
      <mat-card style="width: 100%;    margin-bottom: 3%;">
        <div class="security-page__arrive-btn"
             (click)="downloadFile(carPrice.value, carNightPrice.value, carMoreThan3HoursPrice.value, carMoreThan3HoursNightPrice.value, truckNightPrice.value, truckMoreThan3HoursPrice.value, truckMoreThan3HoursNightPrice.value, truckPrice.value, onFootPrice.value, dateM.value)"
             mat-dialog-close>
          <mat-icon>
            download
          </mat-icon>
        </div>
        <mat-checkbox
          [(ngModel)]="colored"
          style="margin: auto; margin-top: 3%;">
          <span *ngIf="colored">Цветной документ</span>
          <span *ngIf="!colored">Документ на печать</span>
        </mat-checkbox>
      </mat-card>
    </div>
    <div class="add-pass-popup__main">
      <div class="add-pass-popup__info-guest">
        <mat-card style="width: 100%;    margin-bottom: 3%;">
          <span class="add-pass-popup__span-title">Дата:</span>
          <input #dateM class="security-page__arrive-input" [matDatepicker]="dp" [formControl]="date">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp
                          startView="multi-year"
                          (yearSelected)="chosenYearHandler($event)"
                          (monthSelected)="chosenMonthHandler($event, dp)"
                          panelClass="example-month-picker">
          </mat-datepicker>
        </mat-card>
        <mat-card class="add-pass-popup__label-surname">
          <span class="add-pass-popup__span-title">Легковой а/м до 3,5тн ₽</span>
          <input #carPrice type="number" class="security-page__arrive-input" placeholder="Легковой а/м до 3,5тн ₽"
                 value="0">
          <span class="add-pass-popup__span-title">Легковой а/м до 3,5тн (ночь) ₽</span>
          <input #carNightPrice type="number" class="security-page__arrive-input"
                 placeholder="Легковой а/м до 3,5тн (ночь) ₽" value="100">
          <span class="add-pass-popup__span-title">Легковой а/м до 3,5тн больше 3х часов ₽</span>
          <input #carMoreThan3HoursPrice type="number" class="security-page__arrive-input"
                 placeholder="Легковой а/м до 3,5тн больше 3х часов ₽" value="150">
          <span class="add-pass-popup__span-title">Легковой а/м до 3,5тн больше 3х часов (ночь) ₽</span>
          <input #carMoreThan3HoursNightPrice type="number" class="security-page__arrive-input"
                 placeholder="Легковой а/м до 3,5тн больше 3х часов (ночь) ₽" value="0">
          <span class="add-pass-popup__span-title">А/м свыше 3,5 тн (ночь) ₽</span>
          <input #truckNightPrice type="number" class="security-page__arrive-input"
                 placeholder="А/м свыше 3,5 тн (ночь) ₽" value="100">
          <span class="add-pass-popup__span-title">А/м свыше 3,5 тн больше 3х часов ₽</span>
          <input #truckMoreThan3HoursPrice type="number" class="security-page__arrive-input"
                 placeholder="А/м свыше 3,5 тн больше 3х часов ₽" value="150">
          <span class="add-pass-popup__span-title">А/м свыше 3,5 тн больше 3х часов (ночь) ₽</span>
          <input #truckMoreThan3HoursNightPrice type="number" class="security-page__arrive-input"
                 placeholder="А/м свыше 3,5 тн больше 3х часов (ночь) ₽" value="0">
          <span class="add-pass-popup__span-title">А/м свыше 3,5 тн ₽</span>
          <input #truckPrice type="number" class="security-page__arrive-input" placeholder="А/м свыше 3,5 тн ₽"
                 value="150">
          <span class="add-pass-popup__span-title">Пешком ₽</span>
          <input #onFootPrice type="number" class="security-page__arrive-input" placeholder="Пешком ₽" value="100">
        </mat-card>
      </div>
    </div>
  </div>
</div>
