import {EventEmitter, Injectable, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import * as signalR from "@microsoft/signalr";
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService} from './local-storage.service';
import {CheckpointPass} from './models/checkpointPass.model';
import {Roles} from './models/roles.moel';
import {User} from './models/user.model';

@Injectable({
  providedIn: 'root'
})
export class SignalRService implements OnInit {
  public connection = new signalR.HubConnectionBuilder()
    .withUrl("/Hub", {accessTokenFactory: () => this.localStorag.get("access_token")})
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Debug)
    .build();
  private user!: User | null;
  public onconnected = new BehaviorSubject(null);
  public onconnecting = new BehaviorSubject(null);

  constructor(private localStorag: LocalStorageService, private router: Router) {
  }

  ngOnInit(): void {

  }

  public async connect() {
    this.onconnecting.next(null);
    console.log("Connecting to SignalR...");
    this.user = this.localStorag.get("user");
    var role: string | null = localStorage.getItem("role");
    if (this.connection.state != signalR.HubConnectionState.Connected && role != Roles.Guest) {
      await this.connection.start().then(() => {
        this.connection.send("Connect", this.user?.organizationId.toString(), this.user?.id.toString());
        this.localStorag.set("SignalR", this.connection.state);
        console.log(this.connection.state);
        this.connection.onclose(() => {
          this.localStorag.set("SignalR", this.connection.state);
          console.log("Disconnected");
          this.connect();
        });
        this.connection.onreconnecting(() => {
          this.localStorag.set("SignalR", this.connection.state);
          console.log("Reconnecting");
        });
        this.connection.onreconnected(() => {
          this.localStorag.set("SignalR", this.connection.state);
          this.connection.send("Connect", this.user?.organizationId.toString(), this.user?.id.toString());
          console.log("Reconnected");
        });
        this.onconnected.next(null);
        console.log(this.connection.state);
      }).catch((err: any) => console.log(err));
    }
  }
}
