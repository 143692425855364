<!-- <mat-progress-bar mode="query" *ngIf="tabs.length == 0"></mat-progress-bar> *ngIf="tabs.length == 0" -->
<div class="users-list" style="background-color: rgba(245, 245, 220, 0);">
  <div class="users-list__wrapper">
    <div class="container">
      <div class="users-list__top">
        <div class="users-list__top-main">
          <div class="users-list__title">Интеграция</div>
          <div class="users-list__line"></div>
          <button mat-raised-button (click)="openSwagger()" class="swagger-button">
            Документация (Swagger)
          </button>
        </div>

        <div class="api-list__add">
          <!-- <div class="select js-selector-add ">
              <input class="select__input" type="hidden" name="">
              <div class="select__head">
                  <div class="select__header-img-block">
                      <img class=" images select__header-img" src="img/plus-small-add-white.svg" alt="">
                  </div>
                  <div class="select__header-text">
                      Добавить
                  </div>
              </div>

          </div> -->
          <button mat-raised-button (click)="createIntegrationKeyDialog()"
                  style="margin: auto; background: #ea5c0a; color: white;">
            Создать ключ
            <mat-icon>vpn_key</mat-icon>
          </button>
        </div>
      </div>

      <div class="users-list__blocks-companies">
        <mat-expansion-panel style="height: 100%; margin-top: 3%; color: #fff;"
                             *ngFor="let integrationKey of integrationKeys">
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #fff;">
              {{integrationKey.name}}
            </mat-panel-title>
            <mat-panel-description style="color: #fff;">
              {{integrationKey.description}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title">PublicKey</span>
          <div style="position: relative;">
            <input disabled class="security-page__arrive-input apiInput" placeholder="PublickKey"
                   [(ngModel)]="integrationKey.publicKey">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="integrationKey.publicKey">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <span style="color: #fff;" class="add-pass-popup__span-title">SecretKey</span>
          <div style="position: relative;">
            <input disabled class="security-page__arrive-input apiInput" placeholder="SecretKey"
                   [(ngModel)]="integrationKey.secretKey">
            <button mat-raised-button class="copyButton" [cdkCopyToClipboard]="integrationKey.secretKey">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-raised-button class="deleteButton" (click)="deleteIntegrationKey(integrationKey.id)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
