import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-create-integration-key',
  templateUrl: './create-integration-key.component.html',
  styleUrls: ['./create-integration-key.component.css']
})
export class CreateIntegrationKeyComponent implements OnInit {

  constructor(public http: HttpClient) {
  }

  ngOnInit(): void {
  }

  createIntegrationKey(name: string, description: string) {
    this.http.get('/IntegrationKeys/CreateKey?name=' + name + '&description=' + description, {}).subscribe();
  }
}
