<div class="choose-div" role="group" aria-label="Basic example">
  <button type="button" class="btn btn-secondary group-button" *ngIf="isGroup" (click)="duplicatePass(true)"
          mat-dialog-close>Дублировать группу
  </button>
  <button type="button" class="btn btn-secondary group-button" *ngIf="isGroup"
          (click)="duplicatePassCanChangeAfterAdd(true)" mat-dialog-close>Дублировать группу (изменить перед отправкой)
  </button>
  <button type="button" class="btn btn-secondary single-button" (click)="duplicatePass(false)" mat-dialog-close>
    Дублировать пропуск
  </button>
  <button type="button" class="btn btn-secondary single-button" (click)="duplicatePassCanChangeAfterAdd(false)"
          mat-dialog-close>Дублировать пропуск (изменить перед отправкой)
  </button>
</div>
