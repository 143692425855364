import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AppComponent} from './app.component';
import {LocalStorageService} from './local-storage.service';
import {Roles} from './models/roles.moel';
import {User} from './models/user.model';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(private router: Router, public localStorag: LocalStorageService, private http: HttpClient) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    let logedIn: boolean = this.localStorag.get("logedIn");
    const userRole = this.localStorag.get("role");
    if (logedIn == true) {
      if (route.data.userRoles && route.data.userRoles.indexOf(userRole) === -1) {
        this.router.navigate(['..']);
        return false;
      } else {
        let currentUserNeedChangePassword: boolean = false;
        this.http.get<boolean>('/Account/CurrentUserNeedChangePassword').subscribe((response) => {
          currentUserNeedChangePassword = response;
          if (currentUserNeedChangePassword) {
            this.router.navigate(["/entrance-new-password"]);
            return false;
          } else return true;
        });
      }

      return true;
    } else if (userRole == Roles.Guest && url == "/entrance") {
      // this.router.navigate(['/entrance']);
      return true;
    } else if (userRole == Roles.Guest && url == "/entrance-sms") {
      // this.router.navigate(['/entrance']);
      return true;
    } else this.router.navigate(['/entrance']);
    return false;
  }
}
