import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-pwa-install-ios',
  templateUrl: './pwa-install-ios.component.html',
  styleUrls: ['./pwa-install-ios.component.css']
})
export class PwaInstallIosComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
