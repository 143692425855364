<div class="entrance">
  <div class="entrance__wrapper">
    <div class="entrance__logo-block">
      <!-- <a class="entrance__logo-img" href="#">
          <img class="images entrance__logo-img" src="img/logo-big.png" alt="logo">
      </a> -->
    </div>
    <div class="entrance__text entrance-sms__title">
      Подтверждение <br>
      оплаты
    </div>

    <div class="entrance__block-form">
      <form class="entrance__form" action="">
        <div class="entrance__reform-text entrance-sms__text">
          Сумма
          <input disabled value="{{toTrueFormat(amount)}} ₽" class="card-edit-popup__input"
                 style="text-align: center; font-size: 38px; color: black!important;">
          Описание
          <input disabled value="{{description}}" class="card-edit-popup__input"
                 style="text-align: center; font-size: 18px; color: black!important;">
        </div>
        <button class="entrance__button entrance-sms-button" (click)="redirectToSber()">Подтвердить</button>
      </form>
    </div>
    <div class="entrance__block-form" style="margin-top: 1%; height: 230px;">
      <form class="entrance__form" action="">
        <div class="entrance__reform-text entrance-sms__text">
          Принимаем оплату картами и электорнными деньгами
          <p>
            <mat-checkbox class="example-margin" [checked]="acceptCondition">Принимаю</mat-checkbox>
            <a style="color: orangered;" (click)="openConditionsDialog()"> условия</a>
          </p>
          <div class="payment-icons">
            <img src="/img/mir.svg" class="emiteer-icon" alt="">
            <img src="/img/logo-mastercard.svg" class="emiteer-icon" alt="">
            <img src="/img/visa.svg" class="emiteer-icon" alt="">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
