<div class="entrance">
  <div class="entrance__wrapper">
    <div class="entrance__logo-block">
      <!-- <a class="entrance__logo-img" href="#">
          <img class="images entrance__logo-img" src="img/logo-big.png" alt="logo">
      </a> -->
    </div>
    <div class="entrance__text entrance-sms__title">
      СБЕР
    </div>

    <div class="entrance__block-form">
      <form class="entrance__form" action="">
        <div class="entrance__reform-text entrance-sms__text">
          Сумма
          <input #amount type='text' mask="separator.2" thousandSeparator="" class="card-edit-popup__input"
                 style="text-align: center;">
          Описание
          <input #description class="card-edit-popup__input" style="text-align: center;">
        </div>
        <a class="entrance__button entrance-sms-button" (click)="generateLink(amount.value, description.value)">Сгенерировать</a>
      </form>
    </div>
    <div class="entrance__block-form">
      <form class="entrance__form" action="">
        <div class="entrance__reform-text entrance-sms__text">
          Ссылка на оплату
          <P style="color:coral">{{linkForPay}}</P>
        </div>
      </form>
    </div>
  </div>
</div>
