import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-waiting-for-submit',
  templateUrl: './waiting-for-submit.component.html',
  styleUrls: ['./waiting-for-submit.component.css']
})
export class WaitingForSubmitComponent implements OnInit {

  constructor(public http: HttpClient) {
  }

  ngOnInit(): void {
  }

}
