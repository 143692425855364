<div class="list-container">
  <span *ngFor="let str of data">{{str}}</span>
</div>

<style>
  .list-container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .list-container span {
    margin-top: 5px;
  }
</style>
