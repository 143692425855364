import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AddAquaringComponent} from '../add-aquaring/add-aquaring.component';
import {OrderService} from '../service/order.service';

@Component({
  selector: 'app-order-aquarings',
  templateUrl: './order-aquarings.component.html',
  styleUrls: ['./order-aquarings.component.css']
})
export class OrderAquaringsComponent extends OrderService implements OnInit {

  constructor(
    protected http: HttpClient,
    protected dialog: MatDialog
  ) {
    super(http);
  }

  ngOnInit(): void {
  }

  public addAquaringDialog() {
    const dialogRef = this.dialog.open(AddAquaringComponent);
  }

}
