import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AquaringSberRequest} from 'src/app/models/aquaringSber.models';

@Component({
  selector: 'app-add-aquaring',
  templateUrl: './add-aquaring.component.html',
  styleUrls: ['./add-aquaring.component.css']
})
export class AddAquaringComponent implements OnInit {

  constructor(protected http: HttpClient) {
  }

  public paymentData: AquaringSberRequest = {
    name: "",
    amount: "",
    description: "",
    floatingCost: false
  };

  public addAquaring() {
    let amountNumber = Number(this.paymentData.amount);
    amountNumber = amountNumber * 100;
    this.paymentData.amount = amountNumber.toString();
    this.http.post('/api/AquaringSber/Create', this.paymentData).subscribe(result => {
    });
  }

  ngOnInit(): void {
  }

}
