<table class="example-full-width" cellspacing="0">
  <tr>
    <td>
      <button mat-raised-button>Експрот</button>
    </td>
    <td>
      <button mat-raised-button color="primary">Импорт</button>
    </td>
  </tr>
</table>
