<div class="security-page__departure-details-wrapper">
  <div class="security-page__arrive-id">{{data.rfId}}</div>
  <div class="security-page__arrive-time">
    <div class="security-page__arrive-hours">{{now.getUTCHours() + 3}}</div>
    <div class="security-page__arrive-time-dots">:</div>
    <div class="security-page__arrive-min">{{now.getMinutes()}}</div>
    <div class="security-page__arrive-time-dots">:</div>
    <div class="security-page__arrive-sec">{{now.getSeconds()}}</div>
  </div>
  <label class="security-page__departure-label">
    <input type="checkbox" class="security-page__departure-input js-security-page__departure-input"
           [(ngModel)]="checked">
    <span class="security-page__departure-fake"></span>
    <span class="security-page__departure-text">Пропуск сдан</span>
  </label>
  <button class="security-page__departure-btn" (click)="departure()" mat-dialog-close
          [ngClass]="{'js-active' : checked}"
          [disabled]="!checked">
    Гость уехал
  </button>
</div>
