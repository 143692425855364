<div class="entrance">
  <div class="entrance__wrapper">
    <div class="entrance__logo-block">
      <a class="entrance__logo-img">
        <img class="images entrance__logo-img" src="img/logo-big.png" alt="logo">
      </a>
    </div>
    <div class="entrance__text">Страница не найдена</div>
    <div class="entrance__text">(404)</div>
  </div>
</div>
