<div class="card-add-popup js-card-add-popup">
  <div class="card-add-popup__wrapper">
    <div class="card-edit-popup__top">
      <div class="card-edit-popup__title">Добавить пользователя</div>
    </div>
    <div class="card-edit-popup__hr"></div>
    <div class="card-edit-popup__full-info">
      <div class="card-edit-popup__edit-photo">
        <img class="card-edit-popup__chose-photo" src="img/card-edit-popup-mask.png" alt="chose">
      </div>
      <div class="card-edit-popup__infos">
        <label>
          <span class="card-edit-popup__label">Фамилия:</span>
          <input [(ngModel)]="userReg.lastName" type="text" class="card-edit-popup__input">
        </label>
        <label>
          <span class="card-edit-popup__label">Имя:</span>
          <input [(ngModel)]="userReg.firstName" type="text" class="card-edit-popup__input">
        </label>
        <label>
          <span class="card-edit-popup__label">Отчество</span>
          <input [(ngModel)]="userReg.patronymic" type="text" class="card-edit-popup__input">
        </label>
      </div>
    </div>
    <div class="card-edit-popup__others-info">
      <div class="card-edit-popup__other-block">
        <div class="card-user-popup__icon-link">
          <img class="images card-user-popup__icon-link-img" src="img/hove-svg.svg" alt="home">
        </div>
        <div class="card-add-popup__occupation-block">
          <div class="card-edit-popup__selector">
            <div class="card-edit-popup__label">
              Должность:
            </div>
            <mat-select [(ngModel)]="userReg.claimedRole">
              <mat-option *ngFor="let role of roles" value="role">{{role}}</mat-option>
            </mat-select>
          </div>
          <div class="card-edit-popup__selector">
            <div class="card-edit-popup__label">
              Компания:
            </div>
            <mat-select [(ngModel)]="userReg.organizationId">
              <mat-option *ngFor="let organization of organizations"
                          [value]="organization.id">{{organization.name}}</mat-option>
            </mat-select>
          </div>
        </div>
      </div>
      <div class="card-edit-popup__other-block-contact">
        <div class="card-user-popup__icon-link">
          <img class="images card-user-popup__icon-link-img" src="img/phone-svg.svg" alt="home">
        </div>
        <label>
          <span class="card-edit-popup__label">Контактный телефон:</span>
          <input prefix="+7 " mask="(000) 000-00-00" [(ngModel)]="userReg.phone" type="tel"
                 class="card-edit-popup__input">
        </label>
      </div>
      <div class="card-edit-popup__other-block-contact">
        <div class="card-user-popup__icon-link">
          <img class="images card-user-popup__icon-link-img" src="img/main-svg.svg" alt="home">
        </div>
        <label>
          <span class="card-edit-popup__label">Email:</span>
          <input [(ngModel)]="userReg.email" type="email" class="card-edit-popup__input">
        </label>
      </div>
    </div>
    <div class="card-edit-popup__bottoms">
      <div class="card-edit-popup__actions">
        <button class="card-edit-popup__removal js-add-removal" mat-dialog-close>Отмена</button>
        <button class="card-edit-popup__conservation js-card-edit-popup__conservation" (click)="addUser()"
                mat-dialog-close>Сохранить
        </button>
      </div>
    </div>
  </div>
</div>
