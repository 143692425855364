import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PaymentDataFromClient, QrPayment} from 'src/app/models/paymentData.model';

@Injectable({
  providedIn: 'root'
})
export class AddQrCodeService {

  constructor(protected http: HttpClient) {
  }

  public paymentData: PaymentDataFromClient = {
    sum: 0.00,
    firstName: "",
    lastName: "",
    middleName: "",
    payerAddress: "",
    purpose: ""
  };

  public generateQrCode() {
    this.http.put<QrPayment>('/api/Payment/GenerateQrCode', this.paymentData).subscribe(result => {
    });
  }
}
