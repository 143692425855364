<div class="add-organization-popup js-add-organization-popup">
  <div class="add-organization-popup__wrapper">
    <div class="add-organization-popup__title">Добавление организации</div>
    <div class="add-organization-popup__hr"></div>
    <div class="add-organization-popup__block">
      <div class="card-edit-popup__edit-photo">
        <img class="card-edit-popup__chose-photo" src="img/card-edit-popup-mask.png" alt="chose">
      </div>
      <div class="add-organization-popup__block-inputs">
        <div class="add-organization-popup__selector">
          <div class="card-edit-popup__label">
            Форма:
          </div>
          <mat-select [(ngModel)]=organization.form>
            <mat-option value="ООО ">ООО</mat-option>
            <mat-option value="ИП ">ИП</mat-option>
            <mat-option value="АО ">АО</mat-option>
            <mat-option value="ЗАО ">ЗАО</mat-option>
          </mat-select>
        </div>
        <label>
          <span class="card-edit-popup__label">Наименование:</span>
          <input type="text" class="card-edit-popup__input" [(ngModel)]=organization.name>
        </label>
      </div>
    </div>
    <div class="add-organization-popup__address">
      <div class="card-user-popup__icon-link">
        <img class="images card-user-popup__icon-link-img" src="img/hove-svg.svg" alt="home">
      </div>
      <label>
        <span class="card-edit-popup__label">Адрес:</span>
        <input type="text" class="card-edit-popup__input" [(ngModel)]=organization.address>
      </label>
    </div>
    <div class="card-edit-popup__bottoms">
      <div class="card-edit-popup__actions">
        <button class="card-edit-popup__removal js-add-organization-removal" mat-dialog-close>Отмена</button>
        <button class="card-edit-popup__conservation js-organization-popup__continue" (click)="addOrganization()"
                mat-dialog-close>Продолжить
        </button>
      </div>
    </div>
  </div>
</div>
