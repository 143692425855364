<!--<mat-progress-bar mode="query" style="position: fixed;" *ngIf="paymentData.length == 0"></mat-progress-bar> *ngIf="tabs.length == 0"-->
<div class="users-list" style="background-color: rgba(245, 245, 220, 0);">
  <div class="users-list__wrapper">
    <div class="container">
      <div class="users-list__top">
        <div class="users-list__top-main">
          <div class="users-list__title">Продукты</div>
          <div class="users-list__line"></div>
          <!-- <button mat-raised-button (click)="openSwagger()" class="swagger-button">
              Документация (Swagger)
          </button> -->
        </div>
        <div class="security-page__create js-my-page__createe">
          <div class="security-page__create-img-block">
            <img class=" images " src="img/plus-small-add-white.svg" alt="">
          </div>
          <div class="security-page__create-text">
            Добавить продукт
          </div>
        </div>
      </div>

      <!-- <div class="users-list__blocks-companies">
          <mat-card *ngFor="let paymentQr of getPaymentData()" class="item">
              <img src="data:image/png;base64,{{paymentQr.qrCodeBase64}}" class="item-img" alt=""><br>
              <div class="inside-block">
                  <h5 class="">{{paymentQr.sum}}</h5>
                  <h5>{{paymentQr.purpose}}</h5>
              </div>
          </mat-card>
      </div> -->
      <div class="parent">
        <table mat-table [dataSource]="products" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let product"> {{product.name}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="sKU">
            <th mat-header-cell *matHeaderCellDef> SKU</th>
            <td mat-cell *matCellDef="let product"> {{product.sKU}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Price</th>
            <td mat-cell *matCellDef="let product"> {{product.price}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Symbol</th>
            <td mat-cell *matCellDef="let product"> {{product.description}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>


      </div>
    </div>
  </div>
